import { Typography } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';
import { MatterType } from '../../../app/models/matter';

interface Props {
    matter: { matterType: MatterType; leadMatter: boolean } | null | undefined;
    size?: 'small' | 'medium' | 'large';
}

export default function MatterTypeIndicator({ matter, size }: Props) {
    if (!matter || (matter.matterType !== MatterType.GROUP && !matter.leadMatter)) {
        return null;
    }

    const fontSize =
        size === 'small' || size === undefined ? '10px' : size === 'medium' ? '11px' : '12px';

    return (
        <TooltipComponent
            title={
                matter.leadMatter
                    ? 'This is a Lead Matter within a billing group, and linked invoices may be consolidated with those from other matters within this group.'
                    : 'This Group Matter is created to consolidate matters for billing purposes.'
            }
        >
            <Typography.Text
                strong
                style={{
                    color: matter.leadMatter ? ThemeColor.ColorInfo : ThemeColor.ColorError,
                    fontSize: fontSize
                }}
            >
                {matter.leadMatter ? 'LEAD MATTER' : 'GROUP MATTER'}
            </Typography.Text>
        </TooltipComponent>
    );
}
