import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex } from 'antd';
import { ReactNode } from 'react';
import TooltipComponent from './TooltipComponent';

export type ListBulkActionProps = {
    key: string;
    icon: ReactNode;
    label: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: React.ReactNode;
};

export type Props = {
    primary?: ListBulkActionProps;
    secondary?: ListBulkActionProps[];
};

export default function ListBulkActions(props: Props) {
    const { primary, secondary } = props;

    return (
        <Flex gap={8} align='center'>
            {primary && (
                <TooltipComponent title={primary.tooltip}>
                    <Button
                        type='primary'
                        icon={primary.icon}
                        onClick={primary.onClick}
                        disabled={primary.disabled}
                    >
                        {primary.label}
                    </Button>
                </TooltipComponent>
            )}
            {!!secondary?.length && (
                <Dropdown menu={{ items: secondary }}>
                    <Button type='default' onClick={(e) => e.stopPropagation()}>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            )}
        </Flex>
    );
}
