import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useStore } from '../../stores/store';
import { SearchSelectItem } from '../components/searchSelect/SearchSelect';
import LookupFilter from './LookupFilter';

export type ClientLookupFilterSearchSelectItem = SearchSelectItem & { isRelatedClient: boolean };

export type Props = {
    onChange: (
        selectedClients: string[],
        selectedClientItems: ClientLookupFilterSearchSelectItem[],
        includeRelatedClients: boolean
    ) => void;
    defaultIncludeRelatedClients: boolean;
    selectionItems: ClientLookupFilterSearchSelectItem[];
};

export default function ClientLookupFilter(props: Props) {
    const { onChange, selectionItems, defaultIncludeRelatedClients } = props;
    const {
        clientStore: { queryClients }
    } = useStore();
    const [includeRelatedClients, setIncludeRelatedClients] = useState(
        defaultIncludeRelatedClients
    );

    useEffect(() => {
        setIncludeRelatedClients(defaultIncludeRelatedClients);
    }, [defaultIncludeRelatedClients]);

    const someParentClientsSelected = (
        selectedClientItems: ClientLookupFilterSearchSelectItem[]
    ) => {
        return selectedClientItems.some(({ isRelatedClient }) => !!isRelatedClient);
    };

    const handleSearchClient = async (queryText: string) => {
        const results = await queryClients(queryText);
        return (
            results?.items.map((result) => ({
                label: result.name,
                key: result.clientId,
                isRelatedClient: result.hasChildren
            })) ?? []
        );
    };

    const handleClientFilterChange = (
        selectedClients: string[],
        selectedClientItems: ClientLookupFilterSearchSelectItem[]
    ) => {
        const includeRelatedClients_ =
            someParentClientsSelected(selectedClientItems) && includeRelatedClients;
        setIncludeRelatedClients(includeRelatedClients_);
        onChange(selectedClients, selectedClientItems, includeRelatedClients_);
    };

    const handleIncludeRelatedClientsChange = (include: boolean) => {
        setIncludeRelatedClients(include);
        onChange(
            selectionItems.map((item) => item.key),
            selectionItems,
            include
        );
    };

    return (
        <LookupFilter<ClientLookupFilterSearchSelectItem>
            title='Clients'
            key='Clients'
            onSelect={handleClientFilterChange}
            onSearch={handleSearchClient}
            defaultItems={selectionItems}
            defaultSelections={selectionItems.map((item) => item.key)}
            headerLeftComponent={
                someParentClientsSelected(selectionItems) && (
                    <Checkbox
                        checked={includeRelatedClients}
                        onChange={(e) => handleIncludeRelatedClientsChange(e.target.checked)}
                    >
                        Include Related Clients
                    </Checkbox>
                )
            }
        />
    );
}
