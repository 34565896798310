import { Button, Card, Divider, Flex, Input, InputRef, Space, Typography } from 'antd';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Loader';
import { SearchSelectItem } from './SearchSelect';
import SearchSelectItemRenderer from './SearchSelectItemRenderer';
import './styles.css';
type DropdownRenderProps = {
    menus: React.ReactNode;
    items: SearchSelectItem[];
    selections: string[];
    onSearch: (queryText: string) => void;
    onItemSelect: (selection: string, selected: boolean) => void;
    loading: boolean;
    onClearAll: () => void;
    disabledSelections?: string[];
    disableEdit?: boolean;
    headerLeftComponent?: React.ReactNode;
};
export default function DropdownRenderer(props: DropdownRenderProps) {
    const {
        items,
        selections,
        onItemSelect,
        onSearch,
        menus,
        loading,
        onClearAll,
        disabledSelections,
        disableEdit,
        headerLeftComponent
    } = props;
    const searchRef = useRef<InputRef>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    useEffect(() => {
        // Focus on the input without scrolling
        const x = window.scrollX;
        const y = window.scrollY;
        searchRef.current?.focus();
        window.scrollTo(x, y);
    }, []);
    const selectedItems = uniqBy(items, 'key').filter(({ key }) => selections.includes(key));
    const handleClearAll = () => {
        setSearchValue('');
        onClearAll();
    };

    return (
        <Flex vertical wrap='wrap' gap='small' className='select-search__dropdown'>
            <Flex align='center' style={{ width: '100%', padding: '4px' }} justify='space-between'>
                <div>{headerLeftComponent}</div>

                <Button
                    type='link'
                    onClick={handleClearAll}
                    style={{ alignSelf: 'end', paddingRight: 2 }}
                >
                    Clear
                </Button>
            </Flex>
            <Divider style={{ margin: 0 }} />
            <Flex
                vertical
                wrap='wrap'
                gap='small'
                style={{ pointerEvents: disableEdit ? 'none' : undefined }}
            >
                <Input.Search
                    placeholder='Search..'
                    value={searchValue}
                    onSearch={onSearch}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                        onSearch(e.target.value);
                    }}
                    ref={searchRef}
                />
                <Loader spinning={loading} label=''>
                    <Flex style={{ minHeight: 40 }} align='center' justify='center'>
                        {items.length ? (
                            <div style={{ width: '100%' }}>
                                {!!selectedItems.length && (
                                    <Card
                                        size='small'
                                        style={{ backgroundColor: '#EEEEEE', padding: 0 }}
                                        styles={{ body: { padding: 5 } }}
                                    >
                                        <Space
                                            direction='vertical'
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                paddingLeft: 10
                                            }}
                                        >
                                            {selectedItems.map(({ key, label }) => (
                                                <SearchSelectItemRenderer
                                                    label={label}
                                                    checked
                                                    onClick={() => onItemSelect(key, false)}
                                                    key={key}
                                                    disabled={!!disabledSelections?.includes(key)}
                                                />
                                            ))}
                                        </Space>
                                    </Card>
                                )}
                                {React.cloneElement(menus as React.ReactElement, {
                                    style: { boxShadow: 'none', maxHeight: 400, overflow: 'auto' }
                                })}
                            </div>
                        ) : (
                            <Typography.Text type='secondary'>No results</Typography.Text>
                        )}
                    </Flex>
                </Loader>
            </Flex>
        </Flex>
    );
}
