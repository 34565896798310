import {
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EllipsisOutlined,
    FileTextOutlined,
    IssuesCloseOutlined,
    SendOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import {
    Button,
    Dropdown,
    Flex,
    MenuProps,
    Result,
    Space,
    TableColumnsType,
    TableProps
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import DateWithToolTip from '../../../app/common/components/DateWithToolTip';
import ListBulkActions, {
    ListBulkActionProps,
    Props as ListBulkActionsProps
} from '../../../app/common/components/ListBulkActions';
import Loader from '../../../app/common/components/Loader';
import { ThemeColor } from '../../../app/common/constants/color';
import { RowsSelectedLabel } from '../../../app/common/table/RowsSelectedLabel';
import { SearchComponent } from '../../../app/common/table/SearchComponent';
import TableComponent, {
    Props as TableComponentProps
} from '../../../app/common/table/TableComponent';
import { isMultipleClientsSelected } from '../../../app/common/utils/bulkAction';
import { getFormattedDate } from '../../../app/common/utils/datetime';
import { addMenuDivider } from '../../../app/common/utils/objectUtils';
import { getStatementPageKey } from '../../../app/common/utils/statement';
import { AppConfig } from '../../../app/models/config/appConfig';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { PaginationParams } from '../../../app/models/list/pagination';
import { SearchParams } from '../../../app/models/list/search';
import { SortingParams } from '../../../app/models/list/sorting';
import { LIST_ACTION, Statement, STATEMENT_STATE } from '../../../app/models/statement';
import { useStore } from '../../../app/stores/store';
import ClientDoNotContactIndicator from '../../clients/indicators/ClientDoNotContactIndicator';
import ClientDoNotSendStatementIndicator from '../../clients/indicators/ClientDoNotSendStatement';
import RelatedClientIndicator from '../../clients/indicators/RelatedClientIndicator';
import InvoiceNeedsAttentionIndicator from '../../invoices/indicators/InvoiceNeedsAttentionIndicator';
import MatterTypeIndicator from '../../matters/indicators/MatterTypeIndicator';
import EmailListViewer from '../../shared/emailList/EmailListViewer';
import ForeignCurrencyIndicator from '../../shared/indicators/ForeignCurrencyIndicator';
import TruncatedParagraph from '../../shared/table/TruncatedParagraph';
import useStatementActions from '../hooks/useStatementActions';
import StatementDocumentButton from '../Menus/statement/StatementDocumentButton';

export default observer(function StatementList() {
    const navigate = useNavigate();

    const {
        userStore: { setUserPreferences },
        statementStore: {
            statements,
            loadStatements,
            setSortingParams,
            setPagingParams,
            statementStateFilter,
            setSearchParams,
            loadingStatements,
            sortingParams,
            searchParams,
            downloadStatementDocument
        },
        clientStore: { getClient },
        matterStore: { getMatter }
    } = useStore();

    const {
        canClearError,
        selectedStatementIds,
        handleSendClientStatement,
        handleShareStatement,
        handleBulkClearError,
        handleClearError,
        selectedStatements,
        handleRowSelectionChange,
        resetSelections,
        handleSendMatterStatement,
        handleDeleteStatement,
        handleMarkStatementAsSent,
        handleSendStatements,
        canMarkAsSendStatements,
        canSendStatements,
        canShareStatements,
        handleShareStatements
    } = useStatementActions<Statement>();

    useEffect(() => {
        resetSelections();
    }, [statementStateFilter]);

    const currentPageKey = getStatementPageKey(statementStateFilter);

    const handleSorting = (sortColumn: string, sortDirection: 'ascend' | 'descend' | undefined) => {
        setSortingParams(new SortingParams(sortColumn, sortDirection), statementStateFilter);
        loadStatements();
    };

    const handlePagination = (activePage: number) => {
        setPagingParams(new PaginationParams(activePage));
        loadStatements();
    };

    const handlePageSizeChange: TableComponentProps<Statement>['onPageSizeChange'] = (
        _,
        pageSize
    ) => {
        setUserPreferences({ pageSize: pageSize });
        handlePagination(1);
    };

    const handleSearching = (searchString: string) => {
        setSearchParams(new SearchParams(undefined, searchString), statementStateFilter);
        loadStatements();
    };

    const onBulkActionComplete = () => {
        resetSelections();
        loadStatements();
    };

    const getStatementActionMenuItems = (statement: Statement) => {
        const menuItems: MenuProps['items'] = [];

        if (statement.canSend && !statement.client.doNotSendStatement) {
            menuItems.push({
                key: 'send',
                label: 'Send',
                icon: <SendOutlined />,
                onClick: async () => {
                    if (statement.matter?.matterId) {
                        const matter = await getMatter(
                            statement.client.clientId,
                            statement.matter.matterId
                        );
                        if (!matter) {
                            return;
                        }

                        handleSendMatterStatement(matter, statement.statementMonth, true, () =>
                            loadStatements()
                        );
                    } else {
                        const client = await getClient(statement.client.clientId);
                        if (!client) {
                            return;
                        }
                        handleSendClientStatement(client, statement.statementMonth, true, () =>
                            loadStatements()
                        );
                    }
                }
            });
        }

        if (statement.canMarkAsSent) {
            menuItems.push({
                key: 'markAsSent',
                label: 'Mark Sent',
                icon: <CheckOutlined />,
                onClick: async () => {
                    handleMarkStatementAsSent(statement, () => loadStatements());
                }
            });
        }

        if (statement.canDelete) {
            menuItems.push({
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined style={{ color: ThemeColor.ColorError }} />,
                onClick: () => handleDeleteStatement(statement, false, () => loadStatements())
            });
        }

        if (statement?.canShare) {
            menuItems.push({
                key: 'share',
                label: 'Share',
                icon: <ShareAltOutlined />,
                onClick: () => handleShareStatement(statement)
            });
        }

        if (statement.canDownload) {
            addMenuDivider(menuItems);
            if (statement.statementDocumentName) {
                menuItems.push({
                    key: 'downloadPdf',
                    label: 'Download',
                    icon: <DownloadOutlined />,
                    onClick: () =>
                        downloadStatementDocument(
                            statement.statementId,
                            statement.statementDocumentName
                        )
                });
            }
        }

        if (statement.canClearError) {
            addMenuDivider(menuItems);
            menuItems.push({
                key: 'clearError',
                label: 'Clear Error',
                icon: <IssuesCloseOutlined />,
                onClick: () => handleClearError(statement, () => loadStatements())
            });
        }

        return menuItems;
    };

    const searchPlaceholderText = 'Search client or billing timekeeper';

    const onCell = (statement: Statement) => ({
        onClick: () => {
            navigate(`/statements/${statement.statementId}`);
        },
        style: { cursor: 'pointer' }
    });

    const columns: TableColumnsType<Statement> = [
        {
            title: 'Statement Month',
            dataIndex: 'statementMonth',
            key: 'statementMonth',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Statement) => (
                <Space>
                    {getFormattedDate(record.statementMonth, AppConfig.monthYearFormat)}
                    <InvoiceNeedsAttentionIndicator error={record.error} />
                    <ForeignCurrencyIndicator
                        entityType={ENTITY_TYPE.STATEMENT}
                        currency={record.currency}
                    />
                    <ClientDoNotContactIndicator doNotContact={record?.client.doNotContact} />
                    <ClientDoNotSendStatementIndicator
                        doNotSendStatement={record?.client.doNotSendStatement}
                    />
                </Space>
            ),
            onCell
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: true,
            render: (_, record: Statement) => (
                <Space direction='vertical' size={0}>
                    <RelatedClientIndicator client={record.client} />
                    <TruncatedParagraph
                        data={record.client.name}
                        tooltipInfo={
                            <Space direction='vertical'>
                                {record.client.clientId + ' - ' + record.client.name}
                                <Link
                                    to={`/clients/${record.client.clientId}`}
                                    target='_blank'
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    View Client Details
                                </Link>
                            </Space>
                        }
                    />
                </Space>
            ),
            onCell
        },
        {
            title: 'Matter Name',
            dataIndex: 'matterName',
            key: 'matterName',
            sorter: true,
            render: (matterName, record: Statement) =>
                record.matter && (
                    <Space direction='vertical' size={0}>
                        <MatterTypeIndicator matter={record.matter} />
                        <TruncatedParagraph
                            data={record.matter.name}
                            tooltipInfo={
                                <Space direction='vertical'>
                                    {record.matter.matterId + ' - ' + record.matter.name}
                                    <Link
                                        to={`/clients/${record.client.clientId}/matters/${record.matter.matterId}`}
                                        target='_blank'
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        View Matter Details
                                    </Link>
                                </Space>
                            }
                        />
                    </Space>
                ),
            onCell
        },
        {
            title: 'Timekeeper',
            dataIndex: 'billingTimekeeperName',
            key: 'billingTimekeeperName',
            sorter: true,
            render: (billingTimekeeperName, record: Statement) => (
                <TruncatedParagraph
                    data={billingTimekeeperName}
                    tooltipInfo={record.billingTimekeeperId + ' - ' + billingTimekeeperName}
                />
            ),
            onCell
        },
        {
            title: 'Recipients',
            dataIndex: 'recipients',
            key: 'recipients',
            sorter: false,
            render: (_, record: Statement) => (
                <EmailListViewer emails={record.recipients} maxEmails={1} />
            ),
            onCell
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            className: 'table-column__no-wrap',
            render: (_, record: Statement) => <span>{record.statusName}</span>,
            onCell
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            align: 'right',
            className: 'table-column__no-wrap',
            render: (amount: number) => (
                <NumericFormat
                    displayType='text'
                    prefix={AppConfig.currencySymbol}
                    value={amount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator=','
                />
            ),
            onCell
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            sorter: true,
            className: 'table-column__no-wrap',
            render: (date: Date) => <DateWithToolTip date={date} />,
            onCell
        },
        ...(currentPageKey !== 'statementsNotSent'
            ? [
                  {
                      title: 'Sent On',
                      dataIndex: 'sentOn',
                      key: 'sentOn',
                      sorter: true,
                      className: 'table-column__no-wrap',
                      render: (date: Date) => <DateWithToolTip date={date} />,
                      onCell
                  }
              ]
            : []),
        {
            title: 'Doc',
            dataIndex: 'invoiceDocument',
            key: 'invoiceDocument',
            sorter: false,
            render: (_, statement: Statement) => {
                return <StatementDocumentButton statement={statement} showStatementName={false} />;
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            sorter: false,
            className: 'table-column__no-wrap',
            align: 'center',
            render: (_, statement: Statement) => {
                const menuItems = getStatementActionMenuItems(statement);
                return (
                    <Dropdown
                        disabled={
                            menuItems.every((item) => item?.key === 'divider') || !menuItems.length
                        }
                        menu={{
                            items: menuItems
                        }}
                    >
                        <Button type='text' className='list-action-button'>
                            <EllipsisOutlined className='list-action-button-icon' />
                        </Button>
                    </Dropdown>
                );
            }
        }
    ];

    const rowSelectionProps: TableProps<Statement>['rowSelection'] = {
        onChange: handleRowSelectionChange,
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedStatementIds
    };

    const getListBulkActions: () => ListBulkActionsProps = () => {
        const isMultipleClientsSelected_ = isMultipleClientsSelected(
            selectedStatements.map((s) => s.client.clientId)
        );
        const send: ListBulkActionProps = {
            key: LIST_ACTION.SEND,
            icon: <SendOutlined />,
            onClick: () =>
                handleSendStatements(
                    selectedStatements,
                    isMultipleClientsSelected_,
                    false,
                    onBulkActionComplete
                ),
            label: 'Send'
        };
        const markAsSent: ListBulkActionProps = {
            key: LIST_ACTION.MARK_AS_SENT,
            icon: <SendOutlined />,
            onClick: () =>
                handleSendStatements(
                    selectedStatements,
                    isMultipleClientsSelected_,
                    true,
                    onBulkActionComplete
                ),
            label: 'Mark Sent'
        };
        const share: ListBulkActionProps = {
            key: LIST_ACTION.SHARE,
            icon: <ShareAltOutlined />,
            onClick: () =>
                handleShareStatements(
                    selectedStatements,
                    isMultipleClientsSelected_,
                    resetSelections
                ),
            label: 'Share'
        };
        const clearError: ListBulkActionProps = {
            key: LIST_ACTION.CLEAR_ERROR,
            icon: <IssuesCloseOutlined />,
            onClick: () => handleBulkClearError(selectedStatements, onBulkActionComplete),
            label: 'Clear Errors'
        };

        let primaryAction: ListBulkActionProps | undefined;
        let secondaryActions: ListBulkActionProps[] | undefined;
        if (statementStateFilter === STATEMENT_STATE.NOT_SENT) {
            if (canSendStatements.canDisplay) {
                primaryAction = send;
            }
            if (canMarkAsSendStatements.canDisplay) {
                secondaryActions = [markAsSent];
            }
        } else if (statementStateFilter === STATEMENT_STATE.SENT) {
            if (canShareStatements.canDisplay) {
                primaryAction = share;
            }
        } else if (statementStateFilter === STATEMENT_STATE.NEEDS_ATTENTION) {
            if (canClearError.canDisplay) {
                primaryAction = clearError;
            }
            secondaryActions = [];
            if (canSendStatements.canDisplay) {
                secondaryActions.push(send);
            }
            if (canMarkAsSendStatements.canDisplay) {
                secondaryActions.push(markAsSent);
            }
        }

        return {
            primary: primaryAction,
            secondary: secondaryActions
        };
    };

    return (
        <Loader spinning={false} label=''>
            <Flex vertical gap={8}>
                <Flex wrap='wrap' justify='space-between' align='center'>
                    <SearchComponent
                        key={`search-${statementStateFilter}`}
                        searchPlaceholderText={searchPlaceholderText}
                        loading={loadingStatements}
                        searchParams={searchParams[statementStateFilter]}
                        onSearching={handleSearching}
                    />

                    {!!selectedStatements.length && (
                        <Flex gap={'small'} align='center'>
                            <RowsSelectedLabel
                                count={selectedStatementIds.length}
                                entity='statement'
                                onClear={resetSelections}
                            />
                            <ListBulkActions {...getListBulkActions()} />
                        </Flex>
                    )}
                </Flex>
                <TableComponent<Statement>
                    key={`statement-list-${statementStateFilter}`}
                    columns={columns}
                    rowSelection={rowSelectionProps}
                    onSorting={handleSorting}
                    onPagination={handlePagination}
                    rowKey={'id'}
                    dataSource={statements?.items ?? []}
                    scroll={{ x: true }}
                    loading={loadingStatements}
                    paginationInfo={statements?.paginationInfo}
                    sortingParams={sortingParams[statementStateFilter]}
                    onPageSizeChange={handlePageSizeChange}
                    showSizeChanger
                    emptyText={
                        <Result
                            icon={<FileTextOutlined style={{ color: '#bfbfbf' }} />}
                            subTitle={`No statements match the selected filter criteria`}
                        />
                    }
                />
            </Flex>
        </Loader>
    );
});
