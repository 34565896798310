import { ENTITY_TYPE } from '../../models/entity';

export const getExportEntityListTooltipText = (entity: ENTITY_TYPE) => {
    let entityDisplayName = 'clients';
    switch (entity) {
        case ENTITY_TYPE.CLIENT:
            entityDisplayName = 'clients';
            break;
        case ENTITY_TYPE.MATTER:
            entityDisplayName = 'matters';
            break;
        case ENTITY_TYPE.INVOICE:
            entityDisplayName = 'invoices';
    }
    return `Click to export a list of ${entityDisplayName} using the filters and sorting criteria specified below for a maximum of 1000 ${entityDisplayName}.`;
};

export const exportProfileEntityListTooltiptext = 'Only the first 1000 entries will be exported';
