import { ENTITY_TYPE } from '../../../models/entity';
import { Props } from './NavigationBreadcrumb';

export const getEntityDetailsForNavigationBreadcrumb = (props: Props) => {
    const { entityType } = props;

    switch (entityType) {
        case ENTITY_TYPE.CLIENT:
            return {
                clientId: props.client.clientId,
                clientName: props.client.name,
                matterId: null,
                matterName: null,
                parentClient: props.client.parentClient
            };
        case ENTITY_TYPE.INVOICE:
            return {
                clientId: props.invoice.client.clientId,
                clientName: props.invoice.client.name,
                matterId: props.invoice.matter?.matterId,
                matterName: props.invoice.matter?.name,
                parentClient: props.invoice.client.parentClient
            };
        case ENTITY_TYPE.STATEMENT:
            return {
                clientId: props.statement.client.clientId,
                clientName: props.statement.client.name,
                matterId: props.statement.matter?.matterId,
                matterName: props.statement.matter?.name,
                parentClient: props.statement.client.parentClient
            };
        case ENTITY_TYPE.MATTER:
            return {
                clientId: props.matter.client.clientId,
                clientName: props.matter.client.name,
                matterId: null,
                matterName: null,
                parentClient: props.matter.client.parentClient
            };
        default:
            return {
                clientId: null,
                clientName: null,
                matterId: null,
                matterName: null,
                parentClient: null
            };
    }
};
