import { trim } from 'lodash';
import { INVOICE_STATUS, SUBMIT_EXCEPTION } from '../../../../app/models/invoice';
import { StaticRoutes } from '../../../../app/routing/RoutePaths';

export const getInvoiceSubmitSuccessMessage = (
    number: string,
    markAsSubmitted: boolean,
    submittableEpdfCount: number | null
) => {
    let successDescription = `Invoice #${number} successfully submitted`;
    if (markAsSubmitted) {
        successDescription = `Invoice #${number} was successfully marked as submitted`;
    } else if (submittableEpdfCount) {
        successDescription = `Invoice #${number} and ${
            submittableEpdfCount - 1
        } other invoice(s) successfully submitted`;
    }
    return successDescription;
};

export const getSubmitModalMessage = (
    isPayorInvoice: boolean,
    markAsSubmitted: boolean,
    submitException: SUBMIT_EXCEPTION | null
) => {
    if (!isPayorInvoice && submitException) {
        const message =
            submitException === SUBMIT_EXCEPTION.IN_REVIEW
                ? `The review process is not yet complete.`
                : `The invoice is with the lawyer for submission.`;
        return `${message} Are you sure you want to ${markAsSubmitted ? 'mark the invoice submitted?' : 'submit this invoice?'}`;
    }

    return null;
};

export const getEmptyEmailAddressesEditorTooltip = (isPayorInvoice: boolean) =>
    `The email addresses specified here will apply to all future deliveries for this  ${
        isPayorInvoice ? 'payor' : 'client'
    }`;

export const getSubmitModalTitle = (number: string, markAsSubmitted: boolean) => {
    return markAsSubmitted ? `Mark Submitted - #${number} ` : `Submit Invoice - #${number}`;
};

export const getBulkSubmitSuccessMessage = (invoiceCount: number, markAsSubmitted: boolean) => {
    const successMessagePlaceholder = markAsSubmitted
        ? 'were successfully marked as submitted'
        : 'submitted successfully';
    return `${invoiceCount} invoice(s) ${successMessagePlaceholder}`;
};

export const getBulkShareSuccessMessage = (successCount: number, invoiceCount: number) => {
    return `${successCount} out of ${invoiceCount} invoice(s) were successfully shared`;
};

export const getBulkSubmitModalMessage = (markAsSubmitted: boolean, warn: boolean) => {
    const message = markAsSubmitted
        ? 'Are you sure you want to mark the selected invoices submitted?'
        : 'Are you sure you want to submit the selected invoices?';

    const warningMessagePlaceholder = markAsSubmitted
        ? 'Are you sure you want to mark them as submitted?'
        : 'Are you sure you want to submit them?';

    return warn
        ? `Some of the selected invoices are currently with the timekeeper for review or submission to the client. ${warningMessagePlaceholder}`
        : message;
};

export const getBulkSubmitModalTitle = (markAsSubmitted: boolean) => {
    return markAsSubmitted ? 'Mark Submitted' : 'Submit';
};

export const getSendForSubmissionModalMessage = (billingTimekeeperName: string) => {
    return `Are you sure you want to send this invoice to ${billingTimekeeperName} for submission to the client?`;
};

export const getSendForSubmissionSuccessMessage = (number: string) => {
    return `Invoice #${number} successfully sent to the lawyer for submission`;
};

export const getShareMessage = (isPayorInvoice: boolean) =>
    `Are you sure you want to share this invoice with the ${isPayorInvoice ? 'payor' : 'client'}?`;

export const getShareSuccessMessage = (number: string, isPayorInvoice: boolean) =>
    `Invoice #${number} successfully shared with the ${isPayorInvoice ? 'payor' : 'client'}.`;

export const getOneTimeExceptionEmailEditorTooltip = () =>
    'You can update recipient email addresses here as a one time exception. To modify the recipients for future submissions, please update your accounting system';

export const getClientInvoicesSubmitRelativeRedirectURL = (
    clientId: string,
    isRelatedClient = false
) => {
    const searchParams = new URLSearchParams();
    searchParams.append('clientIds', clientId);
    searchParams.append('statuses', INVOICE_STATUS.READY_TO_SUBMIT);
    if (isRelatedClient) {
        searchParams.append('includeRelatedClients', 'true');
    }
    return `${StaticRoutes.INVOICE_LIST_PENDING}?${searchParams.toString()}`;
};

export const cleanString = (value?: string | null) => {
    const trimmedString = trim(value ?? '');
    return trimmedString.length ? trimmedString : null;
};
