import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { InvoiceURLSearchParamBuilder } from '../common/utils/invoice';
import { URLParams } from '../common/utils/urlParams';
import { ENTITY_TYPE } from '../models/entity';
import { INVOICE_STATE, Invoice } from '../models/invoice';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SortingParams } from '../models/list/sorting';
import { store } from './store';

export enum PAGE_KEY {
    PROFILE_INVOICES_PENDING = 'profileInvoices!pending',
    PROFILE_INVOICES_OUTSTANDING = 'profileInvoices!outstanding',
    PROFILE_INVOICES_PAID = 'profileInvoices!paid',
    PROFILE_INVOICES_REVERSED = 'profileInvoices!reversed'
}

type LoadInvoicesParams = {
    entityType: ENTITY_TYPE.CLIENT | ENTITY_TYPE.MATTER;
    invoiceState: INVOICE_STATE;
} & (
    | { entityType: ENTITY_TYPE.CLIENT; clientId: string }
    | { entityType: ENTITY_TYPE.MATTER; clientId: string; matterId: string }
);

export default class ProfileInvoicesMenuStore {
    invoices: PaginatedResult<Invoice> | null = null;
    loadingInvoices = false;

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([
        PAGE_KEY.PROFILE_INVOICES_PENDING,
        PAGE_KEY.PROFILE_INVOICES_OUTSTANDING,
        PAGE_KEY.PROFILE_INVOICES_PAID,
        PAGE_KEY.PROFILE_INVOICES_REVERSED
    ]);

    getInvoiceStatePageKey = (invoiceState: INVOICE_STATE) => `profileInvoices!${invoiceState}`;

    getInvoiceListUrlParams = (invoiceState: INVOICE_STATE) => {
        const params = new URLSearchParams();
        const pageKey = this.getInvoiceStatePageKey(invoiceState);
        const paginationParams = this.urlParams.getPaginationParams(pageKey);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        // Set default sort to invoicedOn if no sortExpression
        const sortingParams = this.urlParams.getSortingParams(pageKey);
        params.append(
            'orderBy',
            sortingParams.sortExpression.length ? sortingParams.sortExpression : 'invoicedOn'
        );
        return params;
    };

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    loadInvoices = async (params: LoadInvoicesParams) => {
        const { entityType, invoiceState, clientId } = params;
        const paramsBuilder = new InvoiceURLSearchParamBuilder(
            invoiceState as INVOICE_STATE,
            undefined,
            this.getInvoiceListUrlParams(invoiceState)
        ).addReversed(invoiceState === INVOICE_STATE.REVERSED);

        if (invoiceState !== INVOICE_STATE.REVERSED) {
            paramsBuilder.addPaidInFull().addSubmitted();
        }

        let invoices_: PaginatedResult<Invoice> | undefined;

        try {
            this.loadingInvoices = true;
            switch (entityType) {
                case ENTITY_TYPE.CLIENT: {
                    const invoices = await agent.Clients.getInvoices(
                        clientId,
                        paramsBuilder.urlSearchParams
                    );
                    invoices_ = invoices;
                    break;
                }
                case ENTITY_TYPE.MATTER: {
                    const invoices = await agent.Matters.getInvoices(
                        clientId,
                        params.matterId,
                        paramsBuilder.urlSearchParams
                    );
                    invoices_ = invoices;
                    break;
                }
            }
            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = invoices_.paginationInfo.totalPages || 1;
            const pageKey = this.getInvoiceStatePageKey(params.invoiceState) as PAGE_KEY;
            if (this.getPaginationParams(pageKey).pageNumber > totalPages) {
                this.setPaginationParams(pageKey, new PaginationParams(totalPages));
                await this.loadInvoices(params);
                return;
            }

            runInAction(() => {
                if (invoices_) {
                    this.invoices = invoices_;
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingInvoices = false;
            });
        }
    };
}
