import { ClockCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    reminderDue: boolean;
}

export default observer(function ReminderIndicator({ reminderDue }: Props) {
    if (!reminderDue) {
        return null;
    }

    return (
        <TooltipComponent title={'One or more reminders are either overdue or due today'}>
            <ClockCircleFilled
                style={{
                    color: ThemeColor.ColorWarning
                }}
            />
        </TooltipComponent>
    );
});
