import { AuditOutlined, SolutionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbProps, Space } from 'antd';
import { useMemo } from 'react';
import BackButton from '../../../layout/BackButton';
import { Client } from '../../../models/client';
import { ENTITY_TYPE } from '../../../models/entity';
import { Invoice } from '../../../models/invoice';
import { Matter } from '../../../models/matter';
import { Statement } from '../../../models/statement';
import NavigationBreadcrumbItem from './NavigationBreadcrumbItem';
import { getEntityDetailsForNavigationBreadcrumb } from './utils';

export type Props = {
    showBackNavigation?: boolean;
    entityType:
        | ENTITY_TYPE.INVOICE
        | ENTITY_TYPE.MATTER
        | ENTITY_TYPE.STATEMENT
        | ENTITY_TYPE.CLIENT;
} & (
    | {
          entityType: ENTITY_TYPE.INVOICE;
          invoice: Invoice;
      }
    | {
          entityType: ENTITY_TYPE.MATTER;
          matter: Matter;
      }
    | {
          entityType: ENTITY_TYPE.STATEMENT;
          statement: Statement;
      }
    | {
          entityType: ENTITY_TYPE.CLIENT;
          client: Client;
      }
);

export default function NavigationBreadcrumb(props: Props) {
    const { entityType, showBackNavigation } = props;

    const { clientId, clientName, matterId, matterName, parentClient } = useMemo(() => {
        return getEntityDetailsForNavigationBreadcrumb(props);
    }, [entityType]);

    const MAX_LEN = 30;
    const items: BreadcrumbProps['items'] = [];

    if (parentClient) {
        items.push({
            title: (
                <NavigationBreadcrumbItem
                    title={`${parentClient.name}`}
                    toolTip={`Parent Client: ${parentClient.clientId} - ${parentClient.name}`}
                    icon={<TeamOutlined />}
                    link={`/clients/${parentClient.clientId}`}
                    maxLength={MAX_LEN}
                />
            )
        });
    }

    if (entityType !== ENTITY_TYPE.CLIENT) {
        items.push({
            title: (
                <NavigationBreadcrumbItem
                    title={`${clientName}`}
                    toolTip={`Client: ${clientId} - ${clientName}`}
                    icon={<UserOutlined />}
                    link={`/clients/${clientId}`}
                    maxLength={MAX_LEN}
                />
            )
        });
    }

    if ((entityType === ENTITY_TYPE.INVOICE || entityType === ENTITY_TYPE.STATEMENT) && matterId) {
        items.push({
            title: (
                <NavigationBreadcrumbItem
                    title={`${matterName}`}
                    toolTip={`Matter: ${matterId} - ${matterName}`}
                    icon={<AuditOutlined />}
                    link={`/clients/${clientId}/matters/${matterId}`}
                    maxLength={MAX_LEN}
                />
            )
        });
    }

    if (entityType === ENTITY_TYPE.INVOICE && props.invoice?.payorInvoice) {
        // For a reversed Payor invoice, most likely the parent is reversed too but we don't have that reversed on date.
        // Options:
        // 1. Disable the link
        // 2. Use browser back button behavior (potentially checking if back url contains the invoice #)
        // 3. Get reversedon date for parent invoice specified in the DTO.
        // Decided to go with #1 since that is simpler approach for now, and because this is really an edge case.
        items.push({
            title: (
                <NavigationBreadcrumbItem
                    title={`Third-party payor invoice`}
                    icon={<TeamOutlined />}
                    link={
                        props.invoice.reversed
                            ? undefined
                            : `/invoices/${props.invoice.parentInvoiceId}`
                    }
                    disabled={props.invoice.reversed}
                />
            )
        });

        items.push({
            title: (
                <NavigationBreadcrumbItem
                    title={`${props.invoice.payor?.payorId} - ${props.invoice.payor?.name}`}
                    toolTip={`Payor: ${props.invoice.payor?.payorId} - ${props.invoice.payor?.name}`}
                    icon={<SolutionOutlined />}
                    maxLength={MAX_LEN}
                />
            )
        });
    }

    return (
        <Space>
            {showBackNavigation && <BackButton style={{ marginLeft: '-5px' }} />}
            <Breadcrumb items={items} />
        </Space>
    );
}
