import { Form, FormProps, Space } from 'antd';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { NullableType } from '../../../models/utility';
import ActionFormFooter from './ActionFormFooter';
import CcUsers from './components/ActionFormCcUsers';
import EmailAddresses from './components/ActionFormEmailAddresses';
import EmailSubject from './components/ActionFormEmailSubject';
import RichTextEditor from './components/ActionFormRichTextEditor';
import UploadDoc from './components/ActionFormUploadDoc';
import './styles.css';

export type ActionFormRef = {
    disableOk: (disable: boolean) => void;
    updateOkText: (okText: string) => void;
};

type Props<T = any> = {
    okText: string;
    onOk: (data: NullableType<T>) => Promise<any> | any;
    onChange?: (data: T) => Promise<any> | any;
    initialValues?: FormProps['initialValues'];
};

export type ActionFormItemProps = {
    name?: string;
    required?: boolean;
    valuePropName?: string;
    initialValue?: any;
    label?: React.ReactNode;
    onChange?: (value: any) => void;
    // The ActionForm validates to true even if the value is falsy.
    // For ex: In case of a checkbox, when the user unchecks the box,
    // it will still be considered valid.
    // It is an expected behavior. To get around this we would have to add
    // rule transformers, hence to keep it simple we can use this flag.
    ignoreFalsyValue?: boolean;
};

export type ActionFormCompoundItemProps = {
    onChange?: (value: any) => void;
};

type ActionFormComponent = <T = any>(
    props: React.PropsWithChildren<Props<T>> & { ref?: React.Ref<ActionFormRef> }
) => React.ReactElement;

function InternalActionForm<T extends {}>(
    props: React.PropsWithChildren<Props<T>>,
    ref?: React.Ref<ActionFormRef>
) {
    const { onOk, okText, children, onChange, initialValues } = props;
    const [okDisabled, setIsOkDisabled] = useState(false);
    const [okText_, setOkText] = useState(okText);
    const [form] = Form.useForm<T>();

    const handleOnOk = async () => {
        await onOk(form.getFieldsValue());
    };

    const handleOnChange = async (_: any, values: T) => {
        await onChange?.(values);
    };

    useImperativeHandle(ref, () => ({
        disableOk: (disable) => {
            setIsOkDisabled(disable);
        },
        updateOkText: (okText: string) => {
            setOkText(okText);
        }
    }));

    const values = Form.useWatch([], form);
    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setIsOkDisabled(false);
            },
            () => {
                setIsOkDisabled(true);
            }
        );
    }, [values]);

    return (
        <Form<T>
            form={form}
            onValuesChange={handleOnChange}
            layout='vertical'
            colon={false}
            validateMessages={{ required: '' }}
            initialValues={initialValues}
        >
            <Space direction='vertical' style={{ width: '100%' }}>
                {children}
                <ActionFormFooter okText={okText_} onOk={handleOnOk} okDisabled={okDisabled} />
            </Space>
        </Form>
    );
}

const ActionFormFRComponent = React.forwardRef(InternalActionForm) as ActionFormComponent;

type CompoundedComponent = typeof ActionFormFRComponent & {
    Item: typeof Item;
    RichTextEditor: typeof RichTextEditor;
    EmailAddresses: typeof EmailAddresses;
    UploadDoc: typeof UploadDoc;
    CcUsers: typeof CcUsers;
    EmailSubject: typeof EmailSubject;
};

/**
 * @deprecated This component is deprecated. Use AntD Form component directly
 */
const ActionForm = ActionFormFRComponent as CompoundedComponent;

// If we run into issues of adding more Form.Item props, we can remove this
// and use the Form.Item itself, but for now avoiding it since the ActionForm
// might not behave as expected with other props.
const Item = function (props: React.PropsWithChildren<ActionFormItemProps>) {
    const { name, children, required, valuePropName, initialValue, label, ignoreFalsyValue } =
        props;

    return (
        <Form.Item
            name={name}
            required={required}
            rules={[
                { required },
                {
                    validator: (_, value) =>
                        ignoreFalsyValue && !!value === false ? Promise.reject() : Promise.resolve()
                }
            ]}
            valuePropName={valuePropName}
            className='action-modal__item'
            initialValue={initialValue}
            label={label}
        >
            {children}
        </Form.Item>
    );
};

ActionForm.Item = Item;
ActionForm.RichTextEditor = RichTextEditor;
ActionForm.EmailAddresses = EmailAddresses;
ActionForm.UploadDoc = UploadDoc;
ActionForm.CcUsers = CcUsers;
ActionForm.EmailSubject = EmailSubject;

export default ActionForm;
