import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { SearchSelectItem } from '../common/components/searchSelect/SearchSelect';
import { ClientLookupFilterSearchSelectItem } from '../common/filter/ClientLookupFilter';
import Filters from '../common/filter/filters';
import { downloadDocumentBlob } from '../common/utils/file';
import { InvoiceURLSearchParamBuilder } from '../common/utils/invoice';
import { Notify } from '../common/utils/notify';
import { URLParams } from '../common/utils/urlParams';
import { AppConfig } from '../models/config/appConfig';
import { INVOICE_STATE } from '../models/invoice';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import {
    AssignedMatterTag,
    Matter,
    MatterActivity,
    MatterFilter,
    MatterFilters,
    MatterPatchProperties,
    MatterPayor,
    MatterProperty,
    MatterStatement
} from '../models/matter';
import { PAGE_KEY } from '../models/pageConfigs';
import { StatementActivity } from '../models/statement';
import { STORAGE_KEY } from '../models/user';
import { store } from './store';

export default class MatterStore {
    private readonly FILTER_GROUP_ID = 'MatterList';
    matter: Matter | null = null;
    loadingMatter = false;
    allMatters: PaginatedResult<Matter> | undefined;
    loadingAllMatters = false;

    matterPayors: MatterPayor[] | null = null;
    loadingMatterPayors = false;

    matterStatementActivities: StatementActivity[] | null = null;
    loadingMatterStatementActivities = false;

    matterActivities: MatterActivity[] | null = null;
    loadingMatterActivities = false;

    matterStatements: MatterStatement[] | null = null;
    loadingMatterStatements = false;

    updatingMatter = false;

    loadingAssignedMatterTags = false;
    assignedMatterTags: AssignedMatterTag[] = [];
    updatingAssignedMatterTags = false;

    loadingStatement = false;
    loadingStatements = false;

    clientIdsFilterDefaultSelections?: ClientLookupFilterSearchSelectItem[];
    billingTimekeepersFilterDefaultSelections?: SearchSelectItem[];

    constructor() {
        makeAutoObservable(this);
    }

    urlParams = new URLParams([PAGE_KEY.MATTER_LIST]);

    getMatterProfileInvoiceStatePageKey = (invoiceState: INVOICE_STATE) =>
        `matterProfileInvoices!${invoiceState}`;

    myMatters = false;
    matterFilters = new Filters<keyof MatterFilters>(
        this.FILTER_GROUP_ID,
        {
            [MatterFilter.CLIENTS]: [],
            [MatterFilter.AR_BUCKETS]: [],
            [MatterFilter.OVERDUE]: [],
            [MatterFilter.TAG_NAMES]: [],
            [MatterFilter.FOLLOW_UP_PENDING]: [],
            [MatterFilter.BILLING_TIME_KEEPERS]: [],
            [MatterFilter.MATTER_STATUS_KEYS]: [],
            [MatterFilter.OFFICE_KEYS]: [],
            [MatterFilter.PRACTICE_GROUP_KEYS]: [],
            [MatterFilter.MATTER_DND]: [],
            [MatterFilter.INCLUDE_RELATED_CLIENTS]: []
        },
        () => this.resetMatterListPaginationParams()
    );

    get basicUrlParams() {
        const params = new URLSearchParams();

        const paginationParams = this.urlParams.getPaginationParams(PAGE_KEY.MATTER_LIST);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(PAGE_KEY.MATTER_LIST);
        if (sortingParams!.sortExpression.length > 0) {
            params.append('orderBy', sortingParams!.sortExpression);
        }

        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.MATTER_LIST);
        if (searchParams!.searchString) {
            params.append('queryText', searchParams!.searchString);
        }
        return params;
    }

    private getMatterListUrlParams = () => {
        const urlParams = this.basicUrlParams;
        const filters = this.matterFilters.filters;
        filters[MatterFilter.AR_BUCKETS].forEach((arbucket) =>
            urlParams.append('arbuckets', arbucket)
        );
        filters[MatterFilter.TAG_NAMES].forEach((selectedTag) =>
            urlParams.append('tagNames', selectedTag)
        );

        if (filters[MatterFilter.OVERDUE].length > 0) {
            urlParams.append('arbuckets', 'overdue');
        }
        if (filters[MatterFilter.FOLLOW_UP_PENDING].length > 0) {
            urlParams.append('followUpPending', 'true');
        }

        if (filters[MatterFilter.INCLUDE_RELATED_CLIENTS].length > 0) {
            urlParams.append('includeRelatedClients', 'true');
        }

        filters[MatterFilter.BILLING_TIME_KEEPERS].forEach((selectedTimeKeeper) =>
            urlParams.append('billingTimekeeperIds', selectedTimeKeeper)
        );

        filters[MatterFilter.CLIENTS].forEach((selectedClient) =>
            urlParams.append('clientIds', selectedClient)
        );

        filters[MatterFilter.MATTER_DND].forEach((dnd) => {
            urlParams.append(dnd, 'true');
        });

        filters[MatterFilter.MATTER_STATUS_KEYS].forEach((matterStatusKey) => {
            urlParams.append(MatterFilter.MATTER_STATUS_KEYS, matterStatusKey);
        });
        filters[MatterFilter.OFFICE_KEYS].forEach((officeKey) => {
            urlParams.append(MatterFilter.OFFICE_KEYS, officeKey);
        });
        filters[MatterFilter.PRACTICE_GROUP_KEYS].forEach((practiceGroupKey) => {
            urlParams.append(MatterFilter.PRACTICE_GROUP_KEYS, practiceGroupKey);
        });

        if (this.myMatters) {
            urlParams.append('myMatters', 'true');
        }
        return urlParams;
    };

    getMatterProfileInvoicesUrlParams = (invoiceState: INVOICE_STATE) => {
        const params = new URLSearchParams();
        const pageKey = this.getMatterProfileInvoiceStatePageKey(invoiceState);
        const paginationParams = this.urlParams.getPaginationParams(pageKey);
        paginationParams.pageSize = store.userStore.pageSize;
        params.append('pageNumber', paginationParams!.pageNumber.toString());
        params.append('pageSize', paginationParams!.pageSize.toString());

        const sortingParams = this.urlParams.getSortingParams(pageKey);
        if (sortingParams.sortExpression) {
            params.append('orderBy', sortingParams.sortExpression);
        }
        return params;
    };

    // TODO: Remove the pageKey parameter throughout the store during refactring

    getPaginationParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getPaginationParams(pageKey);
    };

    resetMatterListPaginationParams = () => {
        this.setPaginationParams(PAGE_KEY.MATTER_LIST, new PaginationParams());
    };

    getSortingParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSortingParams(pageKey);
    };

    getSearchParams = (pageKey: PAGE_KEY) => {
        return this.urlParams.getSearchParams(pageKey);
    };

    setMyMattersFilter = (myMatters: boolean) => {
        this.myMatters = myMatters;
        localStorage.setItem(STORAGE_KEY.MY_MATTERS, `${myMatters}`);
    };

    setPaginationParams = (pageKey: PAGE_KEY, paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(pageKey, paginationParams);
    };

    setSortingParams = (pageKey: PAGE_KEY, sortingParams: SortingParams) => {
        this.urlParams.setSortingParams(pageKey, sortingParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    setSearchParams = (pageKey: PAGE_KEY, searchParams: SearchParams) => {
        this.urlParams.setSearchParams(pageKey, searchParams);
        this.urlParams.setPaginationParams(pageKey, new PaginationParams());
    };

    loadMatter = async (
        clientId: string,
        matterId: string,
        showLoader = true,
        properties?: MatterProperty[]
    ) => {
        let matter: Matter | null = null;
        try {
            if (showLoader) {
                this.loadingMatter = true;
            }
            const urlParams = new URLSearchParams();
            properties?.forEach((property) => urlParams.append('properties', property));
            matter = await agent.Matters.getMatter(clientId, matterId, urlParams);
            runInAction(() => {
                this.matter = matter;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingMatter = false;
            });
        }
        return matter;
    };
    getMatter = async (clientId: string, matterId: string) => {
        try {
            this.loadingMatter = true;
            const matter = await agent.Matters.getMatter(clientId, matterId, new URLSearchParams());
            return matter;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingMatter = false;
            });
        }
    };

    updateBillToEmails = async (
        clientId: string,
        matterId: string,
        billToEmails: string[],
        provisional: boolean
    ) => {
        let updated = false;
        try {
            await agent.Matters.updateBillToEmails(clientId, matterId, billToEmails, provisional);
            updated = true;
        } catch (err) {
            console.log(err);
            Notify.error('Unable to update emails. Please try again.');
        }
        return updated;
    };

    updateStatementEmails = async (
        clientId: string,
        matterId: string,
        statementEmails: string[],
        provisional: boolean
    ) => {
        let updated = false;
        try {
            await agent.Matters.updateStatementEmails(
                clientId,
                matterId,
                statementEmails,
                provisional
            );
            updated = true;
        } catch (err) {
            console.log(err);
        }
        return updated;
    };

    loadMatterActivities = async (clientId: string, matterId: string) => {
        this.loadingMatterActivities = true;
        try {
            const matterActivities = await agent.Matters.getActivities(clientId, matterId);
            runInAction(() => {
                this.matterActivities = matterActivities;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterActivities = false));
        }
    };

    loadMatterStatementActivities = async (clientId: string, matterId: string) => {
        this.loadingMatterStatementActivities = true;
        try {
            const matterStatementActivities = await agent.Matters.getStatementActivities(
                clientId,
                matterId
            );
            runInAction(() => {
                this.matterStatementActivities = matterStatementActivities;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterStatementActivities = false));
        }
    };

    loadMatterPayors = async (clientId: string, matterId: string) => {
        this.loadingMatterPayors = true;
        try {
            const matterPayors = await agent.Matters.getPayors(clientId, matterId);
            runInAction(() => {
                this.matterPayors = matterPayors;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingMatterPayors = false));
        }
    };

    updateDefaultInvoiceType = async (
        clientId: string,
        matterId: string,
        defaultInvoiceType: string | null,
        cascadeUpdate: boolean
    ) => {
        this.updatingMatter = true;
        try {
            await agent.Matters.updateDefaultInvoiceType(
                clientId,
                matterId,
                defaultInvoiceType,
                cascadeUpdate
            );
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingMatter = false;
            });
        }
    };

    patchMatter = async (
        clientId: string,
        matterId: string,
        patchProperties: MatterPatchProperties
    ) => {
        this.updatingMatter = true;
        try {
            await agent.Matters.patchMatter(clientId, matterId, patchProperties);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.updatingMatter = false;
            });
        }
    };

    lookupMattersByClient = async (clientId: string, queryText: string, limit = 50) => {
        try {
            const params = new URLSearchParams();
            params.append('queryText', queryText);
            params.append('limit', limit.toString());
            const matters = await agent.Matters.lookupMatterByClient(clientId, params);
            return matters;
        } catch (err) {
            console.log(err);
        }
    };

    loadAssignedMatterTags = async (matterId: string) => {
        this.loadingAssignedMatterTags = true;
        try {
            const assignedMatterTags = await agent.Matters.getAssignedMatterTags(matterId);
            runInAction(() => {
                this.assignedMatterTags = assignedMatterTags;
                if (this.matter) {
                    this.matter.tagNames = assignedMatterTags.map((tag) => tag.matterTag.name);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => (this.loadingAssignedMatterTags = false));
        }
    };

    updateAssignedMatterTags = async (matterId: string, tagKeys: string[]) => {
        this.updatingAssignedMatterTags = true;
        try {
            await agent.Matters.updateAssignedMatterTags(matterId, tagKeys);
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.updatingAssignedMatterTags = false;
            });
        }
    };

    exportInvoicesToExcel = async (
        clientId: string,
        matterId: string,
        invoiceState: INVOICE_STATE
    ) => {
        try {
            const paramsBuilder = new InvoiceURLSearchParamBuilder(
                invoiceState as INVOICE_STATE
            ).addReversed(invoiceState === INVOICE_STATE.REVERSED);

            if (invoiceState !== INVOICE_STATE.REVERSED) {
                paramsBuilder.addPaidInFull().addSubmitted();
            }
            const exportedBlob = await agent.Matters.exportInvoicesToExcel(
                clientId,
                matterId,
                paramsBuilder.urlSearchParams
            );
            const fileName = `Matter-${matterId}-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    queryMatters = async (query: string) => {
        try {
            const params = new URLSearchParams();
            params.append('queryText', query);
            const matters = await agent.Matters.lookup(params);
            return matters;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    loadAllMatters = async () => {
        this.loadingAllMatters = true;
        try {
            const matters = await agent.Matters.getAllMatters(this.getMatterListUrlParams());
            runInAction(() => {
                this.allMatters = matters;
            });
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingAllMatters = false;
            });
        }
    };

    exportMattersToExcel = async () => {
        try {
            const exportedBlob = await agent.Matters.exportMattersToExcel(
                this.getMatterListUrlParams()
            );
            const fileName = `Matters-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(exportedBlob, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    clearMatterStatements = () => {
        this.matterStatements = null;
    };

    updateClientIdsFilterDefaultSelections = (selections: ClientLookupFilterSearchSelectItem[]) => {
        this.clientIdsFilterDefaultSelections = selections;
    };

    updateBillingTimekeepersFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        this.billingTimekeepersFilterDefaultSelections = selections;
    };

    clearStore = () => {
        this.matter = null;
        this.matterPayors = null;
        this.matterActivities = null;
        this.matterStatementActivities = null;
        this.matterStatements = null;
        this.assignedMatterTags = [];
    };
}
