import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Space, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { canViewAllEntities, canViewMyEntities } from '../../../app/common/utils/user';
import PageHeader from '../../../app/layout/PageHeader';
import { STATEMENT_STATE } from '../../../app/models/statement';
import { useStore } from '../../../app/stores/store';
import useStatementActions from '../hooks/useStatementActions';
import StatementFilters from './filters/StatementFilters';
import StatementList from './StatementList';

export default observer(function StatementDashboard() {
    const navigate = useNavigate();
    const { handleUploadStatement } = useStatementActions();

    const {
        statementStore: {
            setStatementStateFilter,
            loadStatementStatusAggregratesAndStatements,
            setMyStatementFilter,
            myStatements: savedMyStatements
        },
        uiStore: { setNavigationKeyByStatementState, uiPreferences },
        userStore: { user }
    } = useStore();
    const { statementStateFilter, myStatementsFilter } = useParams<{
        statementStateFilter: string;
        myStatementsFilter: string;
    }>();

    const statementState = statementStateFilter as STATEMENT_STATE;

    const myStatements = myStatementsFilter === 'my';

    const isStatementStateValid = Object.values(STATEMENT_STATE).includes(
        statementState as STATEMENT_STATE
    );

    const pageTitles = new Map([
        [STATEMENT_STATE.SENT, 'Sent Statements'],
        [STATEMENT_STATE.NOT_SENT, 'Not Sent Statements']
    ]);

    const handleMyStatementsToggle = (checked: boolean) => {
        setMyStatementFilter(checked);
        const navigateTo = `/statements/${statementState}/${checked ? 'my' : 'all'}`;
        navigate(navigateTo);
    };

    useEffect(() => {
        (async () => {
            if (isStatementStateValid) {
                if (savedMyStatements !== myStatements) {
                    setMyStatementFilter(myStatements);
                }

                setStatementStateFilter(statementState);
                setNavigationKeyByStatementState(statementState);

                loadStatementStatusAggregratesAndStatements();
            }
        })();
    }, [statementState, myStatements]);

    if (myStatements && user && !canViewMyEntities(user)) {
        return <Navigate to={`/statements/${statementState}/all`} />;
    }
    if (!myStatements && user && !canViewAllEntities(user)) {
        return <Navigate to={`/statements/${statementState}/my`} />;
    }

    return (
        <Row gutter={[16, 12]}>
            <Col span={24}>
                <Flex justify='space-between' align='center'>
                    <Space>
                        <PageHeader
                            title={pageTitles.get(statementState as STATEMENT_STATE) ?? ''}
                        />
                        {uiPreferences.showMyEntitiesFilter && (
                            <>
                                <Switch
                                    checked={myStatements}
                                    onChange={handleMyStatementsToggle}
                                    style={{ marginTop: '-10px' }}
                                    title={
                                        myStatements
                                            ? 'Showing only my statements'
                                            : 'Showing all statements'
                                    }
                                />
                                My Statements
                            </>
                        )}
                    </Space>
                    <Button
                        icon={<UploadOutlined />}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleUploadStatement()}
                    >
                        Upload Statement
                    </Button>
                </Flex>
            </Col>

            <Col span={24}>
                <StatementFilters statementState={statementState} />
            </Col>
            <Col span={24}>{<StatementList />}</Col>
        </Row>
    );
});
