import { TeamOutlined } from '@ant-design/icons';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    thirdParty: boolean | undefined | null;
}

export default function ThirdPartyPayorIndicator({ thirdParty }: Props) {
    if (!thirdParty) {
        return null;
    }
    return (
        <TooltipComponent title='Third-party payor'>
            <TeamOutlined
                style={{
                    color: ThemeColor.ColorInfo
                }}
            />
        </TooltipComponent>
    );
}
