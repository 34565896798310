import { AuditOutlined } from '@ant-design/icons';
import { Flex, Result, Space, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DateWithToolTip from '../../../app/common/components/DateWithToolTip';
import { SearchComponent } from '../../../app/common/table/SearchComponent';
import TableComponent, {
    Props as TableComponentProps
} from '../../../app/common/table/TableComponent';
import { dataFormatters } from '../../../app/common/utils/dashboard';
import { Client } from '../../../app/models/client';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { PaginationParams } from '../../../app/models/list/pagination';
import { SearchParams } from '../../../app/models/list/search';
import { SortingParams } from '../../../app/models/list/sorting';
import { Matter } from '../../../app/models/matter';
import { PAGE_KEY } from '../../../app/models/pageConfigs';
import { useStore } from '../../../app/stores/store';
import AlertMessageIndicator from '../../clients/indicators/AlertMessageIndicator';
import ClientDoNotContactIndicator from '../../clients/indicators/ClientDoNotContactIndicator';
import ClientDoNotSendStatementIndicator from '../../clients/indicators/ClientDoNotSendStatement';
import RelatedClientIndicator from '../../clients/indicators/RelatedClientIndicator';
import ReminderIndicator from '../../clients/indicators/ReminderIndicator';
import ThirdPartyPayorIndicator from '../../clients/indicators/ThirdPartyPayorIndicator';
import ForeignCurrencyIndicator from '../../shared/indicators/ForeignCurrencyIndicator';
import TruncatedParagraph from '../../shared/table/TruncatedParagraph';
import MatterTypeIndicator from '../indicators/MatterTypeIndicator';

type Props = {
    onlyMyMatters: boolean;
};

export default observer(function MatterList(props: Props) {
    const { onlyMyMatters } = props;
    const {
        matterStore: {
            getSortingParams,
            allMatters,
            loadingAllMatters,
            getSearchParams,
            loadAllMatters,
            setPaginationParams,
            setSearchParams,
            setSortingParams,
            setMyMattersFilter
        },
        dictionaryStore: {
            loadingClientTags,
            loadingOffices,
            loadingClientStatuses,
            loadingPracticeGroups
        },
        userStore: { setUserPreferences }
    } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        setMyMattersFilter(onlyMyMatters);
        loadAllMatters();
    }, []);

    const handleSorting = (sortColumn: string, sortDirection: 'ascend' | 'descend' | undefined) => {
        setSortingParams(PAGE_KEY.MATTER_LIST, new SortingParams(sortColumn, sortDirection));
        loadAllMatters();
    };

    const handlePagination = (activePage: number) => {
        setPaginationParams(PAGE_KEY.MATTER_LIST, new PaginationParams(activePage));
        loadAllMatters();
    };

    const handlePageSizeChange: TableComponentProps<Client>['onPageSizeChange'] = (_, pageSize) => {
        setUserPreferences({ pageSize: pageSize });
        handlePagination(1);
    };

    const handleSearching = (searchString: string) => {
        setSearchParams(PAGE_KEY.MATTER_LIST, new SearchParams(undefined, searchString));
        loadAllMatters();
    };

    const onCell = (matter: Matter) => {
        return {
            onClick: () => {
                navigate(`/clients/${matter.client.clientId}/matters/${matter.matterId}`);
            }
        };
    };

    const searchPlaceholderText = 'Search client or billing timekeeper';

    //Common logic will be refactred in CBH-2210

    const columns: TableColumnsType<Matter> = [
        {
            title: 'Id',
            dataIndex: 'matterId',
            key: 'matterId',
            sorter: true,
            render: (_, record: Matter) => (
                <Space size={10}>
                    {record.matterId}
                    <AlertMessageIndicator alertMessage={record?.alertMessage} />
                    <ClientDoNotContactIndicator doNotContact={record?.doNotContact} />
                    <ClientDoNotSendStatementIndicator
                        doNotSendStatement={record.doNotSendStatement}
                    />
                    <ThirdPartyPayorIndicator thirdParty={!!record.thirdParty} />
                    <ReminderIndicator reminderDue={record.reminderDue} />
                    <ForeignCurrencyIndicator
                        entityType={ENTITY_TYPE.MATTER}
                        currency={record.currency}
                    />
                </Space>
            ),
            onCell
        },
        {
            title: 'Matter Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (name: string, matter: Matter) => (
                <Space direction='vertical' size={0}>
                    <MatterTypeIndicator matter={matter} />
                    <TruncatedParagraph data={name} minWidth={300} tooltipInfo={name} />
                </Space>
            ),
            onCell
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: true,
            render: (_, record: Matter) => (
                <Space direction='vertical' size={0}>
                    <RelatedClientIndicator client={record.client} />
                    <TruncatedParagraph
                        data={record.client.name}
                        minWidth={300}
                        tooltipInfo={
                            <Space direction='vertical'>
                                {record.client.clientId + ' - ' + record.client.name}
                                <Link
                                    to={`/clients/${record.client.clientId}`}
                                    target='_blank'
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    View Client Details
                                </Link>
                            </Space>
                        }
                    />
                </Space>
            ),
            onCell
        },
        {
            title: 'Billing Timekeeper',
            dataIndex: 'billingTimekeeperName',
            key: 'billingTimekeeperName',
            sorter: true,
            render: (billingTimekeeperName: string) => (
                <TruncatedParagraph data={billingTimekeeperName} />
            ),
            onCell
        },
        {
            title: 'Total AR',
            dataIndex: 'arTotal',
            key: 'arTotal',
            sorter: true,
            className: 'table-column__no-wrap',
            align: 'right',
            render: (_, record: Matter) => {
                return record.metrics?.arTotal
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.arTotal)
                    : '';
            },
            onCell
        },
        {
            title: 'Overdue AR',
            dataIndex: 'arOverdue',
            key: 'arOverdue',
            className: 'table-column__no-wrap',
            align: 'right',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.arOverdue
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.arOverdue)
                    : '';
            },
            onCell
        },
        {
            title: '0-30 AR',
            dataIndex: 'ar0To30',
            key: 'ar0To30',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.ar0To30
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.ar0To30)
                    : '';
            },
            onCell
        },
        {
            title: '31-60 AR',
            dataIndex: 'ar31To60',
            key: 'ar31To60',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.ar31To60
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.ar31To60)
                    : '';
            },
            onCell
        },
        {
            title: '61-90 AR',
            dataIndex: 'ar61To90',
            key: 'ar61To90',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.ar61To90
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.ar61To90)
                    : '';
            },
            onCell
        },
        {
            title: '91-120 AR',
            dataIndex: 'ar91To120',
            key: 'ar91To120',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.ar91To120
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.ar91To120)
                    : '';
            },
            onCell
        },
        {
            title: '121+ AR',
            dataIndex: 'ar121Plus',
            key: 'ar121Plus',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return record.metrics?.ar121Plus
                    ? dataFormatters['amount'](false, true, 2)(record.metrics?.ar121Plus)
                    : '';
            },
            onCell
        },
        {
            title: 'Billing Frequency',
            dataIndex: 'billingFrequency'
        },
        {
            title: 'Office',
            dataIndex: 'office',
            key: 'office',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return <>{record.office?.name}</>;
            },
            onCell
        },

        {
            title: 'Practice Group',
            dataIndex: 'practiceGroup',
            key: 'practiceGroup',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return <>{record.practiceGroup?.name}</>;
            },
            onCell
        },
        {
            title: 'Last Invoiced On',
            dataIndex: 'lastInvoicedOn',
            key: 'lastInvoicedOn',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return <DateWithToolTip date={record.lastInvoicedOn}></DateWithToolTip>;
            },
            onCell
        },
        {
            title: 'Last Paid On',
            dataIndex: 'lastPaidOn',
            key: 'lastPaidOn',
            className: 'table-column__no-wrap',
            sorter: true,
            render: (_, record: Matter) => {
                return <DateWithToolTip date={record.lastPaidOn}></DateWithToolTip>;
            },
            onCell
        }
    ];

    return (
        <Flex vertical gap={8}>
            <Flex wrap='wrap' justify='space-between' align='center'>
                <SearchComponent
                    key={`search-matterList`}
                    searchPlaceholderText={searchPlaceholderText}
                    loading={loadingAllMatters}
                    searchParams={getSearchParams(PAGE_KEY.MATTER_LIST)}
                    onSearching={handleSearching}
                />
                <></>
            </Flex>
            <TableComponent<Matter>
                key={`matter-list-${onlyMyMatters}`}
                columns={columns}
                onSorting={handleSorting}
                onPagination={handlePagination}
                rowKey='id'
                dataSource={allMatters?.items ?? []}
                scroll={{ x: true }}
                loading={
                    loadingAllMatters ||
                    loadingOffices ||
                    loadingClientTags ||
                    loadingClientStatuses ||
                    loadingPracticeGroups
                }
                paginationInfo={allMatters?.paginationInfo}
                sortingParams={getSortingParams(PAGE_KEY.MATTER_LIST)}
                rowClick
                onPageSizeChange={handlePageSizeChange}
                emptyText={
                    <Result
                        icon={<AuditOutlined style={{ color: '#bfbfbf' }} />}
                        subTitle={`No matters match the selected filter criteria`}
                    />
                }
            />
        </Flex>
    );
});
