import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { range } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../app/common/components/Loader';
import { SearchSelectItem } from '../../../../app/common/components/searchSelect/SearchSelect';
import { ThemeColor } from '../../../../app/common/constants/color';
import BooleanFilter from '../../../../app/common/filter/BooleanFilter';
import CheckboxListFilter from '../../../../app/common/filter/CheckboxListFilter';
import ClientLookupFilter, {
    ClientLookupFilterSearchSelectItem
} from '../../../../app/common/filter/ClientLookupFilter';
import LookupFilter from '../../../../app/common/filter/LookupFilter';
import { dataFormatters } from '../../../../app/common/utils/dashboard';
import { ClientDnd } from '../../../../app/models/client';
import { STATEMENT_STATE, StatementFilter } from '../../../../app/models/statement';
import { useStore } from '../../../../app/stores/store';

type Props = {
    statementState: STATEMENT_STATE;
};

export default observer(function StatementFilters(props: Props) {
    const { statementState } = props;
    const [refresh, setRefresh] = useState(true);
    const {
        matterStore: { queryMatters },
        timekeeperStore: { queryTimekeepers },
        statementStore: {
            statementFilters,
            loadStatements,
            getStatementAggregates,
            statementStatusAggregates,
            loadingStatementStatusAggregates,
            updateBillingTimekeepersFilterDefaultSelections,
            updateClientIdsFilterDefaultSelections,
            billingTimekeepersFilterDefaultSelections,
            clientIdsFilterDefaultSelections,
            matterIdsFilterDefaultSelections,
            updateMatterIdsFilterDefaultSelections
        },

        dictionaryStore: { clientTags, loadClientTags, matterTags, loadMatterTags },
        userStore: { user }
    } = useStore();
    const filters = statementFilters.filters;

    useEffect(() => {
        getStatementAggregates();
        setRefresh((prev) => !prev);
    }, [statementFilters.filters]);

    useEffect(() => {
        if (!clientTags) {
            loadClientTags();
        }
        if (!matterTags) {
            loadMatterTags();
        }
    }, []);

    const handleTimekeeperFilterChange = (
        selectedTimekeepers: string[],
        selectedTimekeeperItems: SearchSelectItem[]
    ) => {
        statementFilters.updateFilter(
            StatementFilter.BILLING_TIME_KEEPERS,
            selectedTimekeepers,
            false
        );
        updateBillingTimekeepersFilterDefaultSelections(selectedTimekeeperItems);
        loadStatements();
    };

    const handleClientFilterChange = (
        selectedClients: string[],
        selectedClientItems: ClientLookupFilterSearchSelectItem[],
        includeRelatedClients: boolean
    ) => {
        statementFilters.updateFilter(StatementFilter.CLIENTS, selectedClients, false);
        updateClientIdsFilterDefaultSelections(selectedClientItems);
        statementFilters.updateFilter(
            StatementFilter.INCLUDE_RELATED_CLIENTS,
            includeRelatedClients ? [StatementFilter.INCLUDE_RELATED_CLIENTS] : [],
            false
        );
        loadStatements();
    };

    const handleMatterFilterChange = (
        selectedMatters: string[],
        selectedMatterItems: SearchSelectItem[]
    ) => {
        statementFilters.updateFilter(StatementFilter.MATTERS, selectedMatters, false);
        updateMatterIdsFilterDefaultSelections(selectedMatterItems);
        loadStatements();
    };

    const statementMonths = range(12).map((i) => {
        const date = dayjs().subtract(i, 'month').format('MMM YYYY');
        return {
            key: date,
            label: date
        };
    });

    const matterDnd = [
        { label: 'Do Not Send Statement', key: ClientDnd.DO_NOT_SEND_STATEMENT },
        { label: 'Do Not Contact', key: ClientDnd.DO_NOT_CONTACT }
    ];

    const handleStatementStatusFilterChange = (key: string, isChecked: boolean) => {
        let statementStatusFilters = [...statementFilters.filters[StatementFilter.STATUSES]];
        if (isChecked) {
            statementStatusFilters.push(key);
        } else {
            statementStatusFilters = statementStatusFilters.filter((status) => status !== key);
        }
        statementFilters.updateFilter(StatementFilter.STATUSES, statementStatusFilters);
        loadStatements();
    };

    const handleSearchTimekeepers = async (queryText: string) => {
        const results = await queryTimekeepers(queryText);
        return (
            results?.map((result) => ({
                label: result.timekeeperUserName,
                key: result.timekeeperId
            })) ?? []
        );
    };

    const handleSearchMatter = async (queryText: string) => {
        const results = await queryMatters(queryText);
        return (
            results?.map((result) => ({
                label: result.name,
                key: result.matterId
            })) ?? []
        );
    };

    const handleStatementMonthFilterChange = (filter: StatementFilter, selectedKeys: string[]) => {
        statementFilters.updateFilter(filter, selectedKeys);
        loadStatements();
    };

    const handleCheckboxListFilterChange = (filter: StatementFilter, selectedKeys: string[]) => {
        statementFilters.updateFilter(filter, selectedKeys);
        loadStatements();
    };

    const handleResetFilters = () => {
        statementFilters.clearAllFilters();
        loadStatements();
        updateBillingTimekeepersFilterDefaultSelections([]);
        updateClientIdsFilterDefaultSelections([]);
        updateMatterIdsFilterDefaultSelections([]);
        setRefresh((refresh) => !refresh);
    };

    return (
        <Flex wrap='nowrap' align='start' key={`refresh-${refresh.valueOf()}`}>
            <Loader spinning={loadingStatementStatusAggregates} label=''>
                <Flex wrap='wrap' gap={8} align='center'>
                    {!user?.isTimekeeper && (
                        <LookupFilter
                            title='Billing Timekeeper'
                            onSelect={handleTimekeeperFilterChange}
                            onSearch={handleSearchTimekeepers}
                            defaultItems={billingTimekeepersFilterDefaultSelections}
                            defaultSelections={billingTimekeepersFilterDefaultSelections?.map(
                                (selection) => selection.key
                            )}
                        />
                    )}
                    <ClientLookupFilter
                        onChange={handleClientFilterChange}
                        selectionItems={clientIdsFilterDefaultSelections ?? []}
                        defaultIncludeRelatedClients={
                            !!statementFilters.filters[StatementFilter.INCLUDE_RELATED_CLIENTS]
                                .length
                        }
                    />
                    <LookupFilter
                        title='Matters'
                        onSelect={handleMatterFilterChange}
                        onSearch={handleSearchMatter}
                        defaultItems={matterIdsFilterDefaultSelections}
                        defaultSelections={matterIdsFilterDefaultSelections?.map(
                            (selection) => selection.key
                        )}
                    />
                    <CheckboxListFilter
                        title='Statement Month'
                        options={statementMonths}
                        defaultSelections={filters[StatementFilter.STATEMENT_MONTHS]}
                        onChange={(selectedKeys) =>
                            handleStatementMonthFilterChange(
                                StatementFilter.STATEMENT_MONTHS,
                                selectedKeys
                            )
                        }
                    />
                    <CheckboxListFilter
                        title='Client Tags'
                        options={
                            clientTags?.map((tag) => ({
                                label: tag.name,
                                key: tag.name
                            })) ?? []
                        }
                        key={StatementFilter.CLIENT_TAG_NAMES}
                        defaultSelections={filters[StatementFilter.CLIENT_TAG_NAMES]}
                        onChange={(selectedKeys) =>
                            handleCheckboxListFilterChange(
                                StatementFilter.CLIENT_TAG_NAMES,
                                selectedKeys
                            )
                        }
                    />
                    <CheckboxListFilter
                        title='Matter Tags'
                        options={
                            matterTags?.map((tag) => ({
                                label: tag.name,
                                key: tag.name
                            })) ?? []
                        }
                        key={StatementFilter.MATTER_TAG_NAMES}
                        defaultSelections={filters[StatementFilter.MATTER_TAG_NAMES]}
                        onChange={(selectedKeys) =>
                            handleCheckboxListFilterChange(
                                StatementFilter.MATTER_TAG_NAMES,
                                selectedKeys
                            )
                        }
                    />
                    <CheckboxListFilter
                        title='Communication'
                        options={matterDnd}
                        key={StatementFilter.STATEMENT_DND}
                        defaultSelections={filters[StatementFilter.STATEMENT_DND]}
                        onChange={(selectedKeys) =>
                            handleCheckboxListFilterChange(
                                StatementFilter.STATEMENT_DND,
                                selectedKeys
                            )
                        }
                    />
                    {statementState === STATEMENT_STATE.SENT && (
                        <Divider
                            type='vertical'
                            style={{ borderColor: ThemeColor.PrimaryGrey, height: 24 }}
                            key='divider'
                        />
                    )}
                    {statementStatusAggregates?.items &&
                        statementStatusAggregates.items
                            .filter(({ key }) => {
                                // Show all other statuses if the state is SENT
                                if (statementState === STATEMENT_STATE.SENT) {
                                    return key !== 'ReadyToSend';
                                }
                                return false;
                            })
                            .map(({ key, value }) => (
                                <React.Fragment key={key}>
                                    <BooleanFilter
                                        key={key}
                                        popoverTitle={value.statusName}
                                        popoverContent={
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Space direction='vertical' size={0}>
                                                        <Typography.Text type='secondary'>
                                                            Count
                                                        </Typography.Text>
                                                        <Typography.Text strong>
                                                            {value.count}
                                                        </Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Space direction='vertical' size={0}>
                                                        <Typography.Text type='secondary'>
                                                            Amount
                                                        </Typography.Text>
                                                        <Typography.Text strong>
                                                            {dataFormatters['amount'](
                                                                false,
                                                                true
                                                            )(value.totalAmount)}
                                                        </Typography.Text>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        }
                                        title={value.statusName}
                                        onChange={(isChecked) =>
                                            handleStatementStatusFilterChange(key, isChecked)
                                        }
                                        defaultChecked={statementFilters.filters[
                                            StatementFilter.STATUSES
                                        ].includes(key)}
                                    />
                                </React.Fragment>
                            ))}
                </Flex>
            </Loader>
            <Button onClick={handleResetFilters} type='link'>
                Reset Filters
            </Button>
        </Flex>
    );
});
