import { PageKey, STATEMENT_STATE, STATEMENT_STATUS } from '../../models/statement';

export class StatementURLSearchParamBuilder {
    urlSearchParams: URLSearchParams;
    statementState?: STATEMENT_STATE;
    constructor(statementState?: STATEMENT_STATE, params?: URLSearchParams) {
        this.urlSearchParams = params ?? new URLSearchParams();
        this.statementState = statementState;
    }

    get isStatementStateNeedsAttention() {
        return this.statementState === STATEMENT_STATE.NEEDS_ATTENTION;
    }

    get isStatementStateNotSent() {
        return this.statementState === STATEMENT_STATE.NOT_SENT;
    }

    get isStatementStateSent() {
        return this.statementState === STATEMENT_STATE.SENT;
    }

    addMyStatements(myStatements: boolean) {
        this.urlSearchParams.append('myStatements', myStatements ? 'true' : 'false');
        return this;
    }

    addBillingTimekeeperIds(billingTimekeeperIds: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            billingTimekeeperIds.forEach((billingTimekeeperId) => {
                this.urlSearchParams.append('billingTimekeeperIds', billingTimekeeperId);
            });
        }
        return this;
    }

    addClientIds(clientIds: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            clientIds.forEach((clientId) => {
                this.urlSearchParams.append('clientIds', clientId);
            });
        }
        return this;
    }

    addStatementMonths(months: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            months.forEach((month) => {
                this.urlSearchParams.append('statementMonths', month);
            });
        }
        return this;
    }

    addExcludeMatterStatement(exclude: boolean) {
        if (exclude) {
            this.urlSearchParams.append('excludeMatterStatements', 'true');
        } else {
            this.urlSearchParams.append('excludeMatterStatements', 'false');
        }

        return this;
    }

    addStatuses(statementStatuses: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            statementStatuses.forEach((statementStatus) => {
                this.urlSearchParams.append('statuses', statementStatus);
            });
        }
        return this;
    }

    addSent() {
        if (this.isStatementStateNotSent) {
            this.urlSearchParams.append('sent', 'false');
        } else if (this.isStatementStateSent) {
            this.urlSearchParams.append('sent', 'true');
        }
        return this;
    }

    addErrorsOnly() {
        if (this.isStatementStateNeedsAttention) {
            this.urlSearchParams.append('errorsOnly', 'true');
        }
        return this;
    }

    addMatterIds(matterIds: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            matterIds.forEach((matterId) => {
                this.urlSearchParams.append('matterIds', matterId);
            });
        }
        return this;
    }

    addMatterTagNames(matterTags: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            matterTags.forEach((tag) => {
                this.urlSearchParams.append('matterTagNames', tag);
            });
        }
        return this;
    }

    addClientTagNames(clientTags: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            clientTags.forEach((tag) => {
                this.urlSearchParams.append('clientTagNames', tag);
            });
        }
        return this;
    }
    addDndFilters(dndFilters: string[]) {
        if (!this.isStatementStateNeedsAttention) {
            dndFilters.forEach((dnd) => {
                this.urlSearchParams.append(dnd, 'true');
            });
        }
        return this;
    }

    addIncludeRelatedClients(includeRelatedClients: boolean) {
        if (!this.isStatementStateNeedsAttention && includeRelatedClients) {
            this.urlSearchParams.append('includeRelatedClients', 'true');
        }
        return this;
    }
}

export const getStatementPageKey: (statementState: STATEMENT_STATE) => PageKey = (
    statementState: STATEMENT_STATE
) => {
    switch (statementState) {
        case STATEMENT_STATE.NOT_SENT:
            return 'statementsNotSent';
        case STATEMENT_STATE.SENT:
            return 'statementsSent';
        case STATEMENT_STATE.NEEDS_ATTENTION:
            return 'needsAttention';
        default:
            throw new Error(`Unknown statement state: ${statementState}`);
    }
};

export const mapStateToStatus = (state: STATEMENT_STATE) => {
    return Object.values(STATEMENT_STATUS).filter((status) => {
        if (state === STATEMENT_STATE.NOT_SENT) {
            return status === STATEMENT_STATUS.ReadyToSend;
        } else {
            return status !== STATEMENT_STATUS.ReadyToSend;
        }
    });
};
