import { Typography } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';
import { Client, RelatedClientIdentifier } from '../../../app/models/client';

interface Props {
    client: RelatedClientIdentifier | Client | undefined;
    size?: 'small' | 'medium' | 'large';
}

export default function RelatedClientIndicator({ client, size }: Props) {
    if (!client) {
        return null;
    }

    const isParent = client.hasChildren;
    const isChild = client.hasParent;

    if (!(isParent || isChild)) {
        return null;
    }

    const fontSize =
        size === 'small' || size === undefined ? '10px' : size === 'medium' ? '11px' : '12px';

    return (
        <TooltipComponent
            title={
                isParent
                    ? `This Client is part of a related client group as a parent.`
                    : `This Client is part of a related client group${client.parentClient ? ` linked to "${client.parentClient.clientId} - ${client.parentClient.name}"` : ''}.`
            }
        >
            <Typography.Text
                strong
                style={{
                    color: isParent ? ThemeColor.ColorError : ThemeColor.ColorInfo,
                    fontSize: fontSize
                }}
            >
                {isParent ? 'PARENT CLIENT' : 'RELATED CLIENT'}
            </Typography.Text>
        </TooltipComponent>
    );
}
