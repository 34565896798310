import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { Cache } from '../common/utils/cache';
import { URLParams } from '../common/utils/urlParams';
import { ClientConversation } from '../models/client';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { PAGE_KEY } from '../models/pageConfigs';
import { store } from './store';

export default class ConversationStore {
    conversations?: PaginatedResult<ClientConversation>;
    mailboxesLastSyncedOn?: string;
    loadingConversations = false;
    deletingConversation = false;
    loadingConversationMessages = false;
    creatingConversationMessage = false;
    deletingConversationMessage = false;
    updatingConversationMessage = false;
    loadingConversationMessageData = false;
    syncingConversations = false;
    urlParams = new URLParams([PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS]);
    emailBlobCache = new Cache<Blob>();
    emailBodyCache = new Cache<string>();

    constructor() {
        makeAutoObservable(this);
    }

    get conversationsUrlParams() {
        const params = new URLSearchParams();
        const searchParams = this.urlParams.getSearchParams(PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS);
        if (searchParams.searchString) {
            params.append('queryText', searchParams.searchString);
        }
        return params;
    }

    // TODO: Improve the url params handling. May be create a base class
    getPaginationParams = () => {
        return this.urlParams.getPaginationParams(PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS);
    };

    getSearchParams = () => {
        return this.urlParams.getSearchParams(PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS);
    };

    setPaginationParams = (paginationParams: PaginationParams) => {
        this.urlParams.setPaginationParams(PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS, paginationParams);
    };

    setSearchParams = (searchParams: SearchParams) => {
        this.urlParams.setSearchParams(PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS, searchParams);
        this.urlParams.setPaginationParams(
            PAGE_KEY.CLIENT_PROFILE_CONVERSATIONS,
            new PaginationParams()
        );
    };

    getConversations = async (clientId: string) => {
        this.loadingConversations = true;
        try {
            const urlParams = this.conversationsUrlParams;
            const conversations = await agent.ClientConversations.getConversations(
                clientId,
                urlParams
            );

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = conversations.paginationInfo.totalPages || 1;
            if (this.getPaginationParams().pageNumber > totalPages) {
                this.setPaginationParams(new PaginationParams(totalPages));
                await this.getConversations(clientId);
                return;
            }

            runInAction(() => {
                this.conversations = conversations;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingConversations = false;
            });
        }
    };

    getConversationMessages = async (clientId: string, conversationId: string) => {
        this.loadingConversationMessages = true;
        try {
            const messages = await agent.ClientConversations.getConversationMessages(
                clientId,
                conversationId
            );
            return messages;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingConversationMessages = false;
            });
        }
    };

    createConversationMessage = async (clientId: string, message: File, comment: string | null) => {
        this.creatingConversationMessage = true;
        try {
            const formData = new FormData();
            if (comment) {
                formData.append('comment', comment);
            }
            formData.append('message', message);
            await agent.ClientConversations.createConversation(clientId, formData);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.creatingConversationMessage = false;
            });
        }
    };

    deleteConversationMessage = async (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) => {
        this.deletingConversationMessage = true;
        try {
            await agent.ClientConversations.deleteConversationMessage(
                clientId,
                conversationId,
                conversationMessageId
            );
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.deletingConversationMessage = false;
            });
        }
    };

    updateConversationMessage = async (
        clientId: string,
        conversationId: string,
        conversationMessageId: string,
        comment: string
    ) => {
        this.updatingConversationMessage = true;
        try {
            await agent.ClientConversations.updateConversationMessage(
                clientId,
                conversationId,
                conversationMessageId,
                comment
            );
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.updatingConversationMessage = false;
            });
        }
    };

    getConversationMessageEmail = async (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) => {
        this.loadingConversationMessageData = true;
        try {
            const email = await agent.ClientConversations.getConversationMessageEmail(
                clientId,
                conversationId,
                conversationMessageId
            );
            return email;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingConversationMessageData = false;
            });
        }
    };

    getConversationMessageEmailBody = async (
        clientId: string,
        conversationId: string,
        conversationMessageId: string
    ) => {
        this.loadingConversationMessageData = true;
        try {
            const emailContent = await agent.ClientConversations.getConversationMessageEmailBody(
                clientId,
                conversationId,
                conversationMessageId
            );
            return emailContent;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingConversationMessageData = false;
            });
        }
    };

    deleteConversation = async (clientId: string, conversationId: string) => {
        this.deletingConversation = true;
        try {
            await agent.ClientConversations.deleteConversation(clientId, conversationId);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => {
                this.deletingConversation = false;
            });
        }
    };

    getMailboxesLastSyncedOn = async () => {
        const cacheKey = 'mailboxLastSyncedOn';
        if (!store.cacheStore.isCacheExpired(cacheKey, 1800)) {
            return;
        }
        try {
            const lastSyncedOn = await agent.ClientConversations.getMailboxesLastSyncedOn();
            runInAction(() => {
                this.mailboxesLastSyncedOn = lastSyncedOn;
            });
            store.cacheStore.updateCacheTimestamp(cacheKey);
        } catch (err) {
            console.log(err);
        }
    };

    syncConversations = async () => {
        this.syncingConversations = true;
        try {
            await agent.Clients.syncConversations();
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.syncingConversations = false;
            });
        }
    };

    clearStore = () => {
        this.emailBlobCache.clear();
        this.emailBodyCache.clear();
    };
}
