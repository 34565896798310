import { FileExcelOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Flex, MenuProps, Row, Space, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { router } from '../..';
import TooltipComponent from '../../app/common/components/TooltipComponent';
import { NAVIGATION_KEY } from '../../app/common/constants/navigationKey';
import { getExportEntityListTooltipText } from '../../app/common/utils/export';
import { canViewAllEntities, canViewMyEntities } from '../../app/common/utils/user';
import PageHeader from '../../app/layout/PageHeader';
import { ENTITY_TYPE } from '../../app/models/entity';
import { useStore } from '../../app/stores/store';
import MatterFilters from './matterlist/MatterFilters';
import MatterList from './matterlist/MatterList';

type Props = {
    showOnlyMine?: boolean;
};

export default observer(function Matters(props: Props) {
    const { showOnlyMine } = props;
    const {
        uiStore: { setNavigationKey, uiPreferences },
        matterStore: { setMyMattersFilter, exportMattersToExcel, loadAllMatters },
        userStore: { user }
    } = useStore();

    useEffect(() => {
        setNavigationKey(NAVIGATION_KEY.Matters);
    }, []);

    if (showOnlyMine && user && !canViewMyEntities(user)) {
        return <Navigate to={`/matters/all`} />;
    }

    if (!showOnlyMine && user && !canViewAllEntities(user)) {
        return <Navigate to={`/matters/my`} />;
    }

    const handleMyMattersFilterToggle = (checked: boolean) => {
        setMyMattersFilter(checked);
        const navigateTo = `/matters/${checked ? 'my' : 'all'}`;
        router.navigate(navigateTo);
        loadAllMatters();
    };

    const overflowActions: MenuProps['items'] = [
        {
            label: (
                <TooltipComponent title={getExportEntityListTooltipText(ENTITY_TYPE.MATTER)}>
                    Export to Excel
                </TooltipComponent>
            ),
            icon: <FileExcelOutlined />,
            key: 'export',
            onClick: exportMattersToExcel
        }
    ];

    return (
        <Row gutter={[16, 12]}>
            <Col span={24}>
                <Flex justify='space-between'>
                    <Space>
                        <PageHeader title={'Matters'} />
                        {uiPreferences.showMyEntitiesFilter && (
                            <>
                                <Switch
                                    checked={showOnlyMine}
                                    onChange={handleMyMattersFilterToggle}
                                    style={{ marginTop: '-10px' }}
                                    title={
                                        showOnlyMine
                                            ? 'Showing only my matters'
                                            : 'Showing all matters'
                                    }
                                />
                                My Matters
                            </>
                        )}
                    </Space>
                    <Dropdown menu={{ items: overflowActions }}>
                        <Button
                            type='default'
                            onClick={(e) => e.stopPropagation()}
                            icon={<MoreOutlined />}
                        />
                    </Dropdown>
                </Flex>
            </Col>
            <Col span={24}>
                <MatterFilters />
            </Col>
            <Col span={24}>
                <MatterList onlyMyMatters={!!showOnlyMine} />
            </Col>
        </Row>
    );
});
