import { Button, Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SearchSelectItem } from '../../../app/common/components/searchSelect/SearchSelect';
import BooleanFilter from '../../../app/common/filter/BooleanFilter';
import CheckboxListFilter from '../../../app/common/filter/CheckboxListFilter';
import ClientLookupFilter, {
    ClientLookupFilterSearchSelectItem
} from '../../../app/common/filter/ClientLookupFilter';
import LookupFilter from '../../../app/common/filter/LookupFilter';
import { ClientDnd } from '../../../app/models/client';
import { MatterFilter, MatterFilters } from '../../../app/models/matter';
import { useStore } from '../../../app/stores/store';

export default observer(function MatterFilters() {
    const { timekeeperStore, dictionaryStore, matterStore } = useStore();
    const {
        loadAllMatters,
        matterFilters,
        clientIdsFilterDefaultSelections,
        billingTimekeepersFilterDefaultSelections,
        updateClientIdsFilterDefaultSelections,
        updateBillingTimekeepersFilterDefaultSelections
    } = matterStore;
    const {
        offices,
        loadOffices,
        matterStatuses,
        loadMatterStatuses,
        matterTags,
        loadMatterTags,
        practiceGroups,
        loadPracticeGroups
    } = dictionaryStore;
    const { queryTimekeepers } = timekeeperStore;
    const filters = matterFilters.filters;

    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        if (!offices) {
            loadOffices();
        }
        if (!matterStatuses) {
            loadMatterStatuses();
        }
        if (!matterTags) {
            loadMatterTags();
        }
        if (!practiceGroups) {
            loadPracticeGroups();
        }
    }, []);

    const arBuckets = [
        { label: '0 to 30', key: 'ar0to30' },
        { label: '31 to 60', key: 'ar31to60' },
        { label: '61 to 90', key: 'ar61to90' },
        { label: '91 to 120', key: 'ar91to120' },
        { label: '121+', key: 'ar121plus' }
    ];

    const matterDnd = [
        { label: 'Do Not Send Statement', key: ClientDnd.DO_NOT_SEND_STATEMENT },
        { label: 'Do Not Contact', key: ClientDnd.DO_NOT_CONTACT }
    ];

    const handleTimekeeperFilterChange = (
        selectedTimekeepers: string[],
        selectedTimekeeperItems: SearchSelectItem[]
    ) => {
        matterFilters.updateFilter(MatterFilter.BILLING_TIME_KEEPERS, selectedTimekeepers, false);
        updateBillingTimekeepersFilterDefaultSelections(selectedTimekeeperItems);
        loadAllMatters();
    };

    const handleClientFilterChange = (
        selectedClients: string[],
        selectedClientItems: ClientLookupFilterSearchSelectItem[],
        includeRelatedClients: boolean
    ) => {
        matterFilters.updateFilter(MatterFilter.CLIENTS, selectedClients, false);
        updateClientIdsFilterDefaultSelections(selectedClientItems);
        matterFilters.updateFilter(
            MatterFilter.INCLUDE_RELATED_CLIENTS,
            includeRelatedClients ? [MatterFilter.INCLUDE_RELATED_CLIENTS] : [],
            false
        );
        loadAllMatters();
    };

    const handleCheckboxListFilterChange = (
        filter: keyof MatterFilters,
        selectedKeys: string[]
    ) => {
        matterFilters.updateFilter(filter, selectedKeys);
        loadAllMatters();
    };

    const handleSearchTimekeeper = async (queryText: string) => {
        const results = await queryTimekeepers(queryText);
        return (
            results?.map((result) => ({
                label: result.timekeeperUserName,
                key: result.timekeeperId
            })) ?? []
        );
    };
    const handleResetFilters = () => {
        matterFilters.clearAllFilters();
        updateClientIdsFilterDefaultSelections([]);
        updateBillingTimekeepersFilterDefaultSelections([]);
        setRefresh((refresh) => !refresh);
        loadAllMatters();
    };

    const createFilters = () => {
        const filters_ = [];
        filters_.push(
            <ClientLookupFilter
                onChange={handleClientFilterChange}
                selectionItems={clientIdsFilterDefaultSelections ?? []}
                defaultIncludeRelatedClients={
                    !!matterFilters.filters[MatterFilter.INCLUDE_RELATED_CLIENTS].length
                }
                key='Client'
            />,
            <LookupFilter
                key={MatterFilter.BILLING_TIME_KEEPERS}
                title='Billing Timekeeper'
                onSelect={handleTimekeeperFilterChange}
                onSearch={handleSearchTimekeeper}
                defaultItems={billingTimekeepersFilterDefaultSelections}
                defaultSelections={billingTimekeepersFilterDefaultSelections?.map(
                    (selection) => selection.key
                )}
            />,
            <BooleanFilter
                key={MatterFilter.FOLLOW_UP_PENDING}
                title={'Follow-Up Pending'}
                onChange={(value) => {
                    matterFilters.updateFilter(
                        MatterFilter.FOLLOW_UP_PENDING,
                        value ? [MatterFilter.FOLLOW_UP_PENDING] : []
                    );
                    loadAllMatters();
                }}
                defaultChecked={!!filters[MatterFilter.FOLLOW_UP_PENDING].length}
            />,
            <BooleanFilter
                key={MatterFilter.OVERDUE}
                title={'Overdue'}
                onChange={(value) => {
                    matterFilters.updateFilter(
                        MatterFilter.OVERDUE,
                        value ? [MatterFilter.OVERDUE] : []
                    );
                    loadAllMatters();
                }}
                defaultChecked={!!filters[MatterFilter.OVERDUE].length}
            />,
            <CheckboxListFilter
                title='AR Buckets'
                options={arBuckets}
                key={MatterFilter.AR_BUCKETS}
                defaultSelections={filters[MatterFilter.AR_BUCKETS] ?? []}
                onChange={(selectedKeys) =>
                    handleCheckboxListFilterChange(MatterFilter.AR_BUCKETS, selectedKeys)
                }
            />,
            <CheckboxListFilter
                title='Matter Tags'
                options={
                    matterTags?.map((tag) => ({
                        label: tag.name,
                        key: tag.name
                    })) ?? []
                }
                key={MatterFilter.TAG_NAMES}
                defaultSelections={filters[MatterFilter.TAG_NAMES]}
                onChange={(selectedKeys) =>
                    handleCheckboxListFilterChange(MatterFilter.TAG_NAMES, selectedKeys)
                }
            />,
            <CheckboxListFilter
                title='Communication'
                options={matterDnd}
                key={MatterFilter.MATTER_DND}
                defaultSelections={filters[MatterFilter.MATTER_DND]}
                onChange={(selectedKeys) =>
                    handleCheckboxListFilterChange(MatterFilter.MATTER_DND, selectedKeys)
                }
            />
        );

        if (offices?.length) {
            filters_.push(
                <CheckboxListFilter
                    title='Office'
                    options={offices.map((office) => ({ label: office.name, key: office.key }))}
                    key={MatterFilter.OFFICE_KEYS}
                    defaultSelections={filters[MatterFilter.OFFICE_KEYS]}
                    onChange={(selectedKeys) =>
                        handleCheckboxListFilterChange(MatterFilter.OFFICE_KEYS, selectedKeys)
                    }
                />
            );
        }

        if (matterStatuses?.length) {
            filters_.push(
                <CheckboxListFilter
                    title='Status'
                    options={matterStatuses.map((status) => ({
                        label: status.name,
                        key: status.key
                    }))}
                    key={MatterFilter.MATTER_STATUS_KEYS}
                    defaultSelections={filters[MatterFilter.MATTER_STATUS_KEYS]}
                    onChange={(selectedKeys) =>
                        handleCheckboxListFilterChange(
                            MatterFilter.MATTER_STATUS_KEYS,
                            selectedKeys
                        )
                    }
                />
            );
        }

        if (practiceGroups?.length) {
            filters_.push(
                <CheckboxListFilter
                    title='Practice Groups'
                    options={practiceGroups.map((status) => ({
                        label: status.name,
                        key: status.key
                    }))}
                    key={MatterFilter.PRACTICE_GROUP_KEYS}
                    defaultSelections={filters[MatterFilter.PRACTICE_GROUP_KEYS]}
                    onChange={(selectedKeys) =>
                        handleCheckboxListFilterChange(
                            MatterFilter.PRACTICE_GROUP_KEYS,
                            selectedKeys
                        )
                    }
                />
            );
        }

        return filters_;
    };

    return (
        <Flex wrap='nowrap' align='start'>
            <Flex wrap='wrap' gap={8} key={`refresh-${refresh}`}>
                {createFilters()}
            </Flex>
            <Button onClick={handleResetFilters} type='link'>
                Reset Filters
            </Button>
        </Flex>
    );
});
