import { reverse } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { IHighlight } from 'react-pdf-highlighter';
import agent, { getErrorData } from '../api/agent';
import { InvoiceURLSearchParamBuilder } from '../common/utils/invoice';
import { Notify } from '../common/utils/notify';
import { CC_USER } from '../models/client';
import {
    DUE_ON_BEFORE,
    DocumentStatusAggregates,
    INVOICE_STATE,
    INVOICE_TYPE,
    Invoice,
    InvoiceActivity,
    InvoiceComment,
    InvoiceDocumentAttachment,
    InvoiceFilter,
    InvoiceFilters,
    InvoiceLookup,
    InvoicePayor,
    InvoiceProperty,
    InvoiceStatus,
    PAID_ON_SINCE,
    VIEWABLE_DOCUMENT_TYPE,
    WRITE_OFF_RESPONSE
} from '../models/invoice';
import { PaginatedResult, PaginationParams } from '../models/list/pagination';
import { SearchParams } from '../models/list/search';
import { SortingParams } from '../models/list/sorting';
import { store } from './store';

import { QueryMemo, QueryResult } from '../models/list/query';

import dayjs from 'dayjs';
import { SearchSelectItem } from '../common/components/searchSelect/SearchSelect';
import { ClientLookupFilterSearchSelectItem } from '../common/filter/ClientLookupFilter';
import Filters from '../common/filter/filters';
import { downloadDocumentBlob } from '../common/utils/file';
import { AppConfig } from '../models/config/appConfig';
import { STORAGE_KEY } from '../models/user';

export default class InvoiceStore {
    private readonly FILTER_GROUP_ID = 'InvoiceList';
    loadingInvoices = false;
    invoices: PaginatedResult<Invoice> | undefined;
    loadingErrorCounts = false;
    queryingInvoices = false;
    loadingInvoice = false;
    loadingInvoiceDocument = false;
    loadingInvoiceLedes = false;
    updatingDoc = false;
    selectedInvoice: Invoice | undefined;
    selectedInvoiceDocument: Blob | undefined | null;
    selectedInvoiceLedes: Blob | undefined;

    loadingInvoiceStatusAggregates = false;
    invoiceStatusAggregates: DocumentStatusAggregates | undefined;

    performingAction = false;

    invoiceTypeFilter: INVOICE_TYPE = INVOICE_TYPE.EPDF;
    invoiceStateFilter: INVOICE_STATE = INVOICE_STATE.PENDING;

    pagingParams = new PaginationParams();

    sortingParams = {
        [INVOICE_STATE.PENDING]: new SortingParams(),
        [INVOICE_STATE.OUTSTANDING]: new SortingParams(),
        [INVOICE_STATE.PAID]: new SortingParams(),
        [INVOICE_STATE.CONSOLIDATED]: new SortingParams(),
        [INVOICE_STATE.NEEDS_ATTENTION]: new SortingParams(),
        [INVOICE_STATE.REVERSED]: new SortingParams()
    };

    searchParams = {
        [INVOICE_STATE.PENDING]: new SearchParams(),
        [INVOICE_STATE.OUTSTANDING]: new SearchParams(),
        [INVOICE_STATE.PAID]: new SearchParams(),
        [INVOICE_STATE.CONSOLIDATED]: new SearchParams(),
        [INVOICE_STATE.NEEDS_ATTENTION]: new SearchParams(),
        [INVOICE_STATE.REVERSED]: new SearchParams()
    };

    comments: InvoiceComment[] = [];

    updatingComment = false;
    creatingComment = false;

    updatingNote = false;
    creatingNote = false;

    annotations: Array<IHighlight> = [];
    loadingAnnotations = false;
    queryMemo = new QueryMemo<InvoiceLookup[]>();

    invoiceActivities: InvoiceActivity[] = [];
    loadingActivities = false;
    invoiceStatuses?: InvoiceStatus[];
    updatingEbillingStatus = false;
    creatingEbillingStatus = false;

    payors: InvoicePayor[] = [];

    attachments: InvoiceDocumentAttachment[] = [];
    loadingAttachments = false;
    creatingAttachments = false;
    deletingAttachment = false;
    loadingAttachmentContent = false;

    updatingInvoiceType = false;

    // We should consider localStore managements when such component
    // specific states increase. Initially created one, but it is not worth
    // it for now. Hence removed and added this directly in the invoice store
    invoiceDetailsMenuTabKey: string | null = null;

    // This flag is used to conditionally initialize the filter search params.
    // Ideally this should not be used. But, we are not updating the search params
    // when filters are updated,(which will be taken care in 5.24) we need this flag
    filtersInitializedFromSearchParams = false;

    myInvoices = false;
    invoiceFilters = new Filters<keyof InvoiceFilters>(
        this.FILTER_GROUP_ID,
        {
            [InvoiceFilter.BILLING_TIME_KEEPERS]: [],
            [InvoiceFilter.BILLERS]: [],
            [InvoiceFilter.CLIENTS]: [],
            [InvoiceFilter.STATUSES]: [],
            [InvoiceFilter.PAID_ON_SINCE]: [],
            [InvoiceFilter.OVERDUE]: [],
            [InvoiceFilter.NEEDS_SUBMISSSION_BY_TIMEKEEPER]: [],
            [InvoiceFilter.INVOICE_SUB_GROUP_STATUS]: [],
            [InvoiceFilter.WRITE_OFF_PENDING]: [],
            [InvoiceFilter.DUE_ON_BEFORE]: [],
            [InvoiceFilter.INCLUDE_RELATED_CLIENTS]: []
        },
        () => this.resetPaginationParams()
    );

    // This is used for the clientId lookup filter default selections
    clientIdsFilterDefaultSelections?: ClientLookupFilterSearchSelectItem[];

    // This is a temporary solution, the design will be decided and implemented
    // in 5.24. Most probably via URL
    billingTimekeepersFilterDefaultSelections?: SearchSelectItem[];
    billersFilterDefaultSelections?: SearchSelectItem[];

    constructor() {
        makeAutoObservable(this);
    }

    getInvoiceListUrlParams = (invoiceState: INVOICE_STATE) => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        this.pagingParams.pageSize = store.userStore.pageSize;
        params.append('pageSize', this.pagingParams.pageSize.toString());
        if (this.sortingParams[invoiceState].sortExpression) {
            params.append('orderBy', this.sortingParams[invoiceState].sortExpression);
        }
        if (this.searchParams[invoiceState].searchString) {
            params.append('queryText', this.searchParams[invoiceState].searchString!);
        }
        return params;
    };

    get invoiceStatusAggregatesUrlParams() {
        const params = new URLSearchParams();
        //params.append('filter', 'createdOn>' + moment().subtract(1, 'year').format('YYYY-MM-DD') + 'Z' );
        return params;
    }

    setMyInvoicesFilter = (myInvoices: boolean) => {
        this.myInvoices = myInvoices;
        localStorage.setItem(STORAGE_KEY.MY_INVOICES, `${myInvoices}`);
    };

    setInvoiceTypeFilter = (invoiceTypeFilter: INVOICE_TYPE) => {
        this.invoiceTypeFilter = invoiceTypeFilter;
        this.resetPaginationParams();
    };

    setInvoiceStateFilter = (invoiceStateFilter: INVOICE_STATE) => {
        this.invoiceStateFilter = invoiceStateFilter;
        this.resetPaginationParams();
    };

    setPagingParams = (pagingParams: PaginationParams) => {
        this.pagingParams = pagingParams;
    };

    setSortingParams = (sortingParams: SortingParams, invoiceState: INVOICE_STATE) => {
        this.sortingParams[invoiceState] = sortingParams;
        this.resetPaginationParams();
    };

    setSearchParams = (searchParams: SearchParams, invoiceState: INVOICE_STATE) => {
        this.searchParams[invoiceState] = searchParams;
        this.resetPaginationParams();
    };

    setInvoiceDetailsMenuTabKey = (activeKey: string) => {
        this.invoiceDetailsMenuTabKey = activeKey;
    };

    setFiltersInitializedFromSearchParams = (initialized: boolean) => {
        this.filtersInitializedFromSearchParams = initialized;

        // When we want to re-initialize the filters, we should remove the saved
        // client and billingtimekeeper searchitems
        if (!initialized) {
            this.updateBillingTimekeepersFilterDefaultSelections([]);
            this.updateClientIdsFilterDefaultSelections([]);
        }
    };

    resetPaginationParams = () => {
        this.setPagingParams(new PaginationParams());
    };

    // This is mainly used for the invoice list page
    loadInvoiceStatusAggregratesAndInvoices = async () => {
        if (
            this.invoiceStateFilter === INVOICE_STATE.PENDING ||
            this.invoiceStateFilter === INVOICE_STATE.OUTSTANDING
        ) {
            await this.loadInvoiceStatusAggregates();
        }
        this.loadInvoices();
    };

    private getInvoiceURLSearchParamsBuilder = () => {
        const invoiceURLSearchParamsBuilder = new InvoiceURLSearchParamBuilder(
            this.invoiceStateFilter,
            this.invoiceTypeFilter,
            this.getInvoiceListUrlParams(this.invoiceStateFilter)
        )
            .addMyInvoices(
                this.invoiceStateFilter !== INVOICE_STATE.NEEDS_ATTENTION && this.myInvoices
            )
            .addInvoiceTypes([this.invoiceTypeFilter])
            .addPaidInFull()
            .addSubmitted()
            .addErrorsOnly()
            .addNeedsSubmission(
                this.invoiceFilters.filters[InvoiceFilter.NEEDS_SUBMISSSION_BY_TIMEKEEPER].length >
                    0
            )
            .addOverdue(this.invoiceFilters.filters[InvoiceFilter.OVERDUE].length > 0)
            .addWriteOff(this.invoiceFilters.filters[InvoiceFilter.WRITE_OFF_PENDING].length > 0)
            .addIncludeRelatedClients(
                this.invoiceFilters.filters[InvoiceFilter.INCLUDE_RELATED_CLIENTS].length > 0
            );

        const selectedBillingTimekeeperIds =
            this.invoiceFilters.filters[InvoiceFilter.BILLING_TIME_KEEPERS];
        const selectedClientIds = this.invoiceFilters.filters[InvoiceFilter.CLIENTS];
        const subGroupStatuses =
            this.invoiceFilters.filters[InvoiceFilter.INVOICE_SUB_GROUP_STATUS];
        const selectedBillerIds = this.invoiceFilters.filters[InvoiceFilter.BILLERS];

        invoiceURLSearchParamsBuilder
            .addBillingTimekeeperIds(selectedBillingTimekeeperIds)
            .addBillerIds(selectedBillerIds)
            .addClientIds(selectedClientIds)
            .addSubGroupStatuses(subGroupStatuses);

        const paidOnSince = this.invoiceFilters.filters[InvoiceFilter.PAID_ON_SINCE];
        invoiceURLSearchParamsBuilder.addPaidOnSince(
            (paidOnSince[0] as PAID_ON_SINCE) ?? PAID_ON_SINCE.LAST_30_DAYS
        );

        const dueOnBefore = this.invoiceFilters.filters[InvoiceFilter.DUE_ON_BEFORE];
        if (dueOnBefore[0]) {
            invoiceURLSearchParamsBuilder.addDueOnBefore(dueOnBefore[0] as DUE_ON_BEFORE);
        }

        if (this.invoiceStateFilter !== INVOICE_STATE.PAID) {
            const selectedInvoiceStatuses = this.invoiceFilters.filters[InvoiceFilter.STATUSES];
            const availableInvoiceStatuses =
                this.invoiceStatusAggregates?.items.map((item) => item.key) ?? [];
            const applicableFilters = availableInvoiceStatuses.filter((invoiceStatus) =>
                selectedInvoiceStatuses.includes(invoiceStatus)
            );
            if (applicableFilters.length) {
                invoiceURLSearchParamsBuilder.addStatuses(applicableFilters);
            }
        }
        return invoiceURLSearchParamsBuilder;
    };

    loadInvoices = async () => {
        store.errorStore.getErrorCounts();
        try {
            this.loadingInvoices = true;
            const builder = this.getInvoiceURLSearchParamsBuilder();
            const invoices = await agent.Invoices.list(builder.urlSearchParams);

            // Reset the page number to total pages and
            // refetch the data if it exceeds total pages
            const totalPages = invoices.paginationInfo.totalPages || 1;
            if (this.pagingParams.pageNumber > totalPages) {
                this.setPagingParams(new PaginationParams(totalPages));
                await this.loadInvoices();
                return;
            }
            runInAction(() => {
                this.invoices = invoices;
                this.loadingInvoices = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loadingInvoices = false));
        }
    };

    querylookupInvoices = async (query: string) => {
        try {
            const queryInvoice = this.queryMemo.getRecord(query);
            if (queryInvoice) {
                return new QueryResult(queryInvoice);
            }
            const params = new URLSearchParams();
            params.append('queryText', query);
            const lookupinvoices = await agent.Invoices.invoiceLookup(params);
            this.queryMemo.addRecord(query, lookupinvoices);
            return new QueryResult(lookupinvoices);
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    getInvoice = async (invoiceId: string) => {
        try {
            return await agent.Invoices.get(invoiceId);
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    // TODO: We should have a cleanup story that specifies how to create the method signature
    // How should the optional param and default params be arranged
    loadInvoice = async (
        invoiceId: string,
        loadDocument: boolean,
        forceReload: boolean,
        showLoader = true,
        invoiceProperties?: InvoiceProperty[]
    ) => {
        try {
            if (
                !forceReload &&
                this.selectedInvoice &&
                this.selectedInvoice.invoiceId === invoiceId
            ) {
                return;
            }
            if (showLoader) {
                this.loadingInvoice = true;
            }
            const params = new URLSearchParams();
            invoiceProperties?.forEach((property) => params.append('properties', property));

            const invoice = await agent.Invoices.get(invoiceId, params);
            const getDocumentParams = new URLSearchParams();

            const invoiceDocument =
                loadDocument && invoice.invoiceDocumentName
                    ? await agent.Invoices.getDocument(invoiceId, getDocumentParams)
                    : null;
            let invoiceLedes: Blob;
            if (invoice.type === INVOICE_TYPE.EBILLING && invoice.invoiceLedesName) {
                getDocumentParams.append('documentType', VIEWABLE_DOCUMENT_TYPE.LEDES);
                invoiceLedes = await agent.Invoices.getDocument(invoiceId, getDocumentParams);
            }
            runInAction(() => {
                this.selectedInvoice = invoice;
                this.comments = reverse(invoice.comments);
                if (invoiceDocument) {
                    this.selectedInvoiceDocument = invoiceDocument;
                }
                if (invoiceLedes) {
                    this.selectedInvoiceLedes = invoiceLedes;
                }
                this.invoiceActivities = invoice.activities;
                this.payors = invoice.payors;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingInvoice = false));
        }
        return {
            invoice: this.selectedInvoice,
            invoiceDocument: this.selectedInvoiceDocument,
            invoiceLedes: this.selectedInvoiceLedes
        };
    };

    loadInvoiceDocument = async (invoiceId: string) => {
        this.loadingInvoiceDocument = true;
        try {
            const invoiceDocument = await agent.Invoices.getDocument(invoiceId);
            return invoiceDocument;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingInvoiceDocument = false));
        }
    };

    submitInvoice = async (
        invoiceId: string,
        canOverrideException = false,
        markAsSubmitted = false,
        submittedOn: string | null,
        emailSubject: string | null,
        emailMessage: string | null,
        emailRecipients: string[] | null,
        ccUsers: CC_USER[] | null
    ) => {
        try {
            const params = new URLSearchParams();
            if (canOverrideException) {
                params.append('canOverrideException', 'true');
            }
            if (markAsSubmitted) {
                params.append('setStatusOnly', 'true');
            }
            if (markAsSubmitted && submittedOn) {
                params.append('submittedOn', submittedOn);
            }

            this.performingAction = true;
            await agent.Invoices.submit(
                invoiceId,
                {
                    ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                    ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                    ccBiller: !!ccUsers?.includes(CC_USER.BILLER),
                    emailMessage,
                    emailSubject,
                    emailRecipients
                },
                params
            );
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
    };

    shareInvoice = async (
        invoiceId: string,
        emailSubject: string | null,
        emailRecipients: string[] | null,
        emailMessage: string | null,
        ccUsers: CC_USER[] | null
    ) => {
        try {
            this.performingAction = true;
            await agent.Invoices.share(invoiceId, {
                emailSubject,
                emailRecipients,
                ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                ccBiller: !!ccUsers?.includes(CC_USER.BILLER),
                emailMessage
            });
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => (this.performingAction = false));
        }
    };

    shareInvoices = async (
        invoiceIds: string[],
        ccUsers: CC_USER[] | null,
        emailMessage: string | null,
        emailSubject: string | null
    ) => {
        try {
            this.performingAction = true;
            await agent.Invoices.bulkShare({
                invoiceIds,
                ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                emailMessage,
                emailSubject
            });
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => (this.performingAction = false));
        }
    };

    completeReviewInvoice = async (invoiceId: string, comment: string | null) => {
        try {
            this.performingAction = true;
            await agent.Invoices.completeReview(invoiceId, comment);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
    };

    sendInvoiceToReview = async (invoiceId: string, comment: string | null) => {
        try {
            await agent.Invoices.sendInvoiceToReview(invoiceId, comment);
            if (this.selectedInvoice && this.selectedInvoice.invoiceId === invoiceId) {
                const invoice = await agent.Invoices.get(invoiceId);
                runInAction(() => {
                    this.selectedInvoice = invoice;
                });
            }
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    completeReviewInvoices = async (invoiceIds: string[], comment: string | null) => {
        this.performingAction = true;
        let errorCount = 0;
        const totalInvoices = invoiceIds.length;
        for (let i = 0; i < totalInvoices; ++i) {
            try {
                await agent.Invoices.completeReview(invoiceIds[i], comment);
            } catch (error: any) {
                console.log(error);
                errorCount++;
            }
        }
        runInAction(() => {
            this.performingAction = false;
        });
        return errorCount;
    };

    sendForSubmission = async (invoiceId: string, comment: string | null) => {
        this.performingAction = true;
        let sentForSubmission = false;
        try {
            await agent.Invoices.sendForSubmission(invoiceId, comment);
            sentForSubmission = true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
        return sentForSubmission;
    };

    submitInvoices = async (
        invoiceIds: string[],
        markAsSubmitted?: boolean,
        submittedOn?: string,
        canOverrideException?: boolean,
        ccUsers?: CC_USER[],
        // TODO: A future task would be to cleanup how the params as passed,
        // currently we make it optional sometimes and nullable sometimes.
        // Eslint rules should be setup to follow common conventions
        emailMessage?: string,
        emailSubject?: string
    ) => {
        let submitted = false;
        this.performingAction = true;
        const params = new URLSearchParams();
        if (canOverrideException) {
            params.append('canOverrideException', 'true');
        }
        if (markAsSubmitted) {
            params.append('setStatusOnly', 'true');
        }
        if (markAsSubmitted && submittedOn) {
            params.append('submittedOn', submittedOn);
        }

        try {
            await agent.Invoices.bulksubmit(
                {
                    invoiceIds,
                    ccCurrentUser: !!ccUsers?.includes(CC_USER.CURRENT_USER),
                    ccBillingTimekeeper: !!ccUsers?.includes(CC_USER.BILLING_TIMEKEEPER),
                    emailMessage: emailMessage ?? null,
                    emailSubject: emailSubject ?? null
                },
                params
            );
            submitted = true;
        } catch (error) {
            console.log(error);
            Notify.error(
                'An error occurred during the submission of invoice(s). Please try again.'
            );
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
        return submitted;
    };

    sendInvoicesToReview = async (invoiceIds: string[], comment: string | null) => {
        this.performingAction = true;
        let errorCount = 0;
        for (let i = 0; i < invoiceIds.length; ++i) {
            try {
                await agent.Invoices.sendInvoiceToReview(invoiceIds[i], comment);
            } catch (error: any) {
                console.log(error);
                errorCount++;
            }
        }
        runInAction(() => {
            this.performingAction = false;
        });
        return errorCount;
    };

    getInvoiceDocument = async (
        invoiceId: string,
        documentType: VIEWABLE_DOCUMENT_TYPE = VIEWABLE_DOCUMENT_TYPE.PDF
    ) => {
        this.loadingInvoiceDocument = true;
        let invoiceDocument: Blob | null = null;
        try {
            const params = new URLSearchParams();
            params.append('documentType', documentType);
            const invoiceDocument_ = await agent.Invoices.getDocument(invoiceId, params);
            invoiceDocument = invoiceDocument_;
        } catch (error: any) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingInvoiceDocument = false;
            });
        }
        return invoiceDocument;
    };

    loadInvoiceStatusAggregates = async () => {
        try {
            this.loadingInvoiceStatusAggregates = true;
            const invoiceURLSearchParamsBuilder = new InvoiceURLSearchParamBuilder(
                this.invoiceStateFilter as INVOICE_STATE,
                this.invoiceTypeFilter as INVOICE_TYPE,
                this.invoiceStatusAggregatesUrlParams
            )
                .addMyInvoices(this.myInvoices)
                .addInvoiceType(this.invoiceTypeFilter as INVOICE_TYPE)
                .addPaidInFull()
                .addSubmitted();

            const invoiceStatusAggregates = await agent.Invoices.getAggregates(
                invoiceURLSearchParamsBuilder.urlSearchParams
            );

            runInAction(() => {
                this.invoiceStatusAggregates = invoiceStatusAggregates;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingInvoiceStatusAggregates = false));
        }
    };

    uploadInvoiceDocument = async (invoiceId: string, document: File) => {
        try {
            this.updatingDoc = true;
            const formData = new FormData();
            formData.append('Document', document);
            await agent.Invoices.uploadInvoiceDocument(invoiceId, formData);
            Notify.success(`Invoice document successfully uploaded`);
            return true;
        } catch (error) {
            console.log(error);
            Notify.error('An error occurred during uploading the invoice. Please try again.');
            return false;
        } finally {
            runInAction(() => {
                this.updatingDoc = false;
            });
        }
    };

    clearSelectedInvoice = () => {
        this.selectedInvoice = undefined;
        this.selectedInvoiceDocument = undefined;
        this.selectedInvoiceLedes = undefined;
        this.invoiceDetailsMenuTabKey = null;
        this.attachments = [];
    };

    // Invoice Comment handlers

    // loadComments = async (invoiceId: string) => {
    //     try{
    //         this.loadingComments = true;
    //         const comments = await agent.Invoices.getComments(invoiceId);
    //         this.comments = comments;
    //     }catch (error) {
    //         Notify.error('An error occurred while loding comments');
    //     } finally {
    //         runInAction(() => {
    //             this.loadingComments = false;
    //         });
    //     }
    // }

    deleteComment = async (commentId: string, invoiceId: string) => {
        let deletedComment: InvoiceComment | undefined;
        try {
            this.updatingComment = true;
            deletedComment = await agent.Invoices.deleteComment(invoiceId, commentId);
        } catch (error: any) {
            console.log(error);
            Notify.error(getErrorData(error).detail);
        } finally {
            runInAction(() => {
                this.updatingComment = false;
                if (deletedComment) {
                    this.updateComment(deletedComment);
                }
            });
        }
        return deletedComment;
    };

    editComment = async (commentId: string, content: string, invoiceId: string) => {
        let updatedComment: InvoiceComment | undefined = undefined;
        try {
            this.updatingComment = true;
            updatedComment = await agent.Invoices.updateComment(invoiceId, commentId, content);
        } catch (error: any) {
            console.log(error);
            Notify.error(getErrorData(error).detail);
        } finally {
            runInAction(() => {
                this.updatingComment = false;
                if (updatedComment) {
                    this.updateComment(updatedComment);
                }
            });
        }
        return updatedComment;
    };

    createComment = async (content: string, invoiceId: string) => {
        let newComment: InvoiceComment | undefined = undefined;
        try {
            this.creatingComment = true;
            newComment = await agent.Invoices.createComment(invoiceId, content);
        } catch (error: any) {
            console.log(error);
            Notify.error(getErrorData(error).detail);
        } finally {
            runInAction(() => {
                this.creatingComment = false;
                if (newComment) {
                    this.addComment(newComment);
                }
            });
        }
        return newComment;
    };

    // This updates the comments instore which removes extra network calls
    addComment = (comment: InvoiceComment) => {
        runInAction(() => {
            this.comments = [comment, ...this.comments];
        });
    };

    updateComment = (updatedComment: InvoiceComment) => {
        runInAction(() => {
            const updatedComments = this.comments.map((comment) => {
                if (comment.id === updatedComment.id) {
                    return updatedComment;
                }
                return comment;
            });
            this.comments = updatedComments;
        });
    };

    updateAnnotations = async (invoiceId: string, annotations: string) => {
        await agent.Invoices.updateAnnotations(invoiceId, annotations);
        runInAction(() => {
            this.annotations = JSON.parse(annotations);
        });
    };

    clearAnnotations = () => {
        runInAction(() => {
            this.annotations = [];
        });
    };

    getAnnotations = async (invoiceId: string) => {
        this.loadingAnnotations = true;
        this.annotations = [];
        try {
            const data = await agent.Invoices.getAnnotations(invoiceId);
            const str = JSON.stringify(data);
            runInAction(() => {
                this.annotations = JSON.parse(str);
            });
        } catch (error) {
            console.log(error);
            Notify.error('An error occurred during loading the annotations. Please try again.');
        } finally {
            runInAction(() => {
                this.loadingAnnotations = false;
            });
        }
    };

    getInvoiceActivities = async (invoiceId: string) => {
        this.loadingActivities = true;
        try {
            if (this.selectedInvoice && invoiceId !== this.selectedInvoice.invoiceId) {
                throw new Error('The invoiceId does not match the selected invoice invoiceId.');
            }

            const data = await agent.Invoices.getInvoiceActivities(invoiceId);
            runInAction(() => {
                this.invoiceActivities = data;
            });
        } catch (error) {
            console.log(error);
            Notify.error('An error occurred during loading the activities. Please try again.');
        } finally {
            runInAction(() => {
                this.loadingActivities = false;
            });
        }
    };

    loadInvoiceStatuses = async () => {
        try {
            const invoiceStatuses = await agent.Invoices.getInvoiceStatues();
            runInAction(() => {
                this.invoiceStatuses = invoiceStatuses;
            });
        } catch (err) {
            console.log(err);
        }
    };

    updateEbillingStatus = async (invoiceId: string, eBillingStatusKey: string) => {
        this.updatingEbillingStatus = true;
        try {
            await agent.Invoices.updateEbillingStatus(invoiceId, eBillingStatusKey);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            runInAction(() => (this.updatingEbillingStatus = false));
        }
    };

    clearError = async (invoiceIds: string[]) => {
        this.performingAction = true;
        try {
            await agent.Invoices.clearError(invoiceIds);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
    };

    requestWriteOff = async (
        invoiceId: string,
        amount: number,
        comment: string | null,
        invoiceWriteOffReasonKey: string | null
    ) => {
        this.performingAction = true;
        try {
            await agent.Invoices.requestWriteOff(
                invoiceId,
                amount,
                comment,
                invoiceWriteOffReasonKey
            );
            return true;
        } catch (error) {
            console.log(error);
            return false;
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
    };

    completeWriteOff = async (invoiceId: string, approved = false, comment: string | null) => {
        this.performingAction = true;
        try {
            await agent.Invoices.completeWriteOff(invoiceId, approved, comment);
            return WRITE_OFF_RESPONSE.COMPLETED;
        } catch (error: any) {
            if (error?.response?.status === 422) {
                return WRITE_OFF_RESPONSE.ALREADY_PROCESSED;
            }
            return WRITE_OFF_RESPONSE.FAILED;
        } finally {
            runInAction(() => {
                this.performingAction = false;
            });
        }
    };

    getWriteOff = async (invoiceId: string) => {
        try {
            const writeOff = await agent.Invoices.getWriteOff(invoiceId);
            return writeOff;
        } catch (err) {
            console.log(err);
        }
    };

    // Attachments handlers
    getAttachments = async (invoiceId: string) => {
        this.loadingAttachments = true;
        try {
            const params = new URLSearchParams();
            params.append('documentType', 'InvoiceDocumentAttachment');
            const attachments = await agent.Invoices.getAttachments(invoiceId, params);
            runInAction(() => {
                this.attachments = attachments;
            });
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingAttachments = false;
            });
        }
    };

    getAttachmentContent = async (invoiceId: string, attachmentId: string) => {
        this.loadingAttachmentContent = true;
        try {
            const content = await agent.Invoices.getAttachmentContent(invoiceId, attachmentId);
            return content;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.loadingAttachmentContent = false;
            });
        }
    };

    createAttachments = async (invoiceId: string, files: File[]) => {
        this.creatingAttachments = true;
        let success = false;
        for (let i = 0; i < files.length; ++i) {
            try {
                const formData = new FormData();
                formData.append('Content', files[i]);
                await agent.Invoices.createAttachment(invoiceId, formData);
                // We consider partial success as well.
                success = true;
            } catch (err) {
                console.log(err);
            }
        }
        runInAction(() => {
            this.creatingAttachments = false;
        });
        return success;
    };

    deleteAttachment = async (invoiceId: string, attachmentId: string) => {
        this.deletingAttachment = true;
        try {
            await agent.Invoices.deleteAttachment(invoiceId, attachmentId);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.deletingAttachment = false;
            });
        }
    };

    exportInvoicesToExcel = async () => {
        try {
            const builder = this.getInvoiceURLSearchParamsBuilder();
            const excelBlob = await agent.Invoices.exportInvoicesToExcel(builder.urlSearchParams);
            const fileName = `Invoices-${dayjs().format(AppConfig.dateFormat)}.xlsx`;
            downloadDocumentBlob(excelBlob, fileName);
        } catch (err) {
            console.log(err);
        }
    };

    updateInvoiceType = async (invoiceId: string, invoiceType: INVOICE_TYPE) => {
        this.updatingInvoiceType = true;
        try {
            await agent.Invoices.updateInvoiceType(invoiceId, invoiceType);
            return true;
        } catch (err) {
            console.log(err);
        } finally {
            runInAction(() => {
                this.updatingInvoiceType = false;
            });
        }
    };

    updateClientIdsFilterDefaultSelections = (selections: ClientLookupFilterSearchSelectItem[]) => {
        runInAction(() => {
            this.clientIdsFilterDefaultSelections = selections;
        });
    };

    updateBillingTimekeepersFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        this.billingTimekeepersFilterDefaultSelections = selections;
    };

    updateBillersFilterDefaultSelections = (selections: SearchSelectItem[]) => {
        this.billersFilterDefaultSelections = selections;
    };

    clearAttachments = () => {
        this.attachments = [];
    };
}
