import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { trimWithEllipsis } from '../../utils/string';
import TooltipComponent from '../TooltipComponent';

type BreadcrumbItemProps = {
    title: string;
    toolTip?: string;
    icon: React.ReactNode;
    link?: string;
    disabled?: boolean;
    maxLength?: number;
};

export default function NavigationBreadcrumbItem(props: BreadcrumbItemProps) {
    const { title, toolTip, icon, link, disabled, maxLength } = props;
    const displayTitle = maxLength ? trimWithEllipsis(title, 30) : title;
    return (
        <TooltipComponent title={toolTip ?? title}>
            {disabled || !link ? (
                <Space>
                    {icon}
                    {displayTitle}
                </Space>
            ) : (
                <Link to={link}>
                    <Space>
                        {icon}
                        {displayTitle}
                    </Space>
                </Link>
            )}
        </TooltipComponent>
    );
}
