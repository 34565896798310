import { QuestionCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import LinkButton from '../../../app/common/components/LinkButton';
import { ThemeColor } from '../../../app/common/constants/color';
import { getFormattedDate, getRelativeTime } from '../../../app/common/utils/datetime';
import { isArrayNullOrEmpty } from '../../../app/common/utils/objectUtils';
import { AppConfig } from '../../../app/models/config/appConfig';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { useStore } from '../../../app/stores/store';
import RelatedClientIndicator from '../../clients/indicators/RelatedClientIndicator';
import MatterTypeIndicator from '../../matters/indicators/MatterTypeIndicator';
import DescriptionsComponent, {
    Props as DescriptionsComponentProps
} from '../../shared/DescriptionsComponent';
import ForeignCurrencyAmount from '../../shared/ForeignCurrency/ForeignCurrencyAmount';

export default observer(function BasicInfoTab() {
    const {
        statementStore: { selectedStatement: statement },
        tenantStore: { tenant }
    } = useStore();

    const basicInfoDescriptions = useMemo(() => {
        let descriptions: DescriptionsComponentProps['descriptions'] = [];
        if (!statement?.statementId) {
            return descriptions;
        }

        descriptions = descriptions.concat([
            {
                label: (
                    <Space>
                        {'Client'}
                        <RelatedClientIndicator client={statement.client} />
                    </Space>
                ),
                tooltipInfo: `${statement.client.clientId} - ${statement.client.name}`,
                description: (
                    <LinkButton href={`/clients/${statement.client.clientId}`} target='_blank'>
                        {statement.client.name}
                    </LinkButton>
                ),
                doNotTruncate: true
            }
        ]);

        if (statement.matter) {
            descriptions.push({
                label: (
                    <Space>
                        {'Matter'}
                        <MatterTypeIndicator matter={statement?.matter} />
                    </Space>
                ),
                tooltipInfo: `${statement?.matter?.matterId} - ${statement?.matter?.name}`,
                description: (
                    <LinkButton
                        href={`/clients/${statement?.client?.clientId}/matters/${statement?.matter?.matterId}`}
                        target='_blank'
                    >
                        {statement?.matter?.name}
                    </LinkButton>
                )
            });
        }

        descriptions.push({
            label: 'Billing Timekeeper',
            tooltipInfo:
                statement.billingTimekeeperId === null
                    ? ''
                    : `${statement.billingTimekeeperId} - ${statement.billingTimekeeperName}`,
            description: statement.billingTimekeeperName ?? '-'
        });

        descriptions.push({
            label: 'Recipients',
            tooltipInfo: isArrayNullOrEmpty(statement?.recipients)
                ? 'There are no email addresses associated with this client or matter. You will be able to specify the email addresses during statement submission.'
                : undefined,
            description: isArrayNullOrEmpty(statement.recipients) ? (
                <QuestionCircleFilled style={{ color: ThemeColor.PrimaryGrey }} />
            ) : (
                statement.recipients?.join(', ')
            )
        });

        descriptions.push({
            label: 'Statement Amount',
            description: (
                <Space>
                    <NumericFormat
                        displayType='text'
                        prefix={AppConfig.currencySymbol}
                        value={statement.amount}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator=','
                    />
                    <ForeignCurrencyAmount
                        enityType={ENTITY_TYPE.STATEMENT}
                        foreignCurrencyAmountKey='amount'
                        entity={statement}
                        tenant={tenant}
                    />
                </Space>
            )
        });

        descriptions.push({
            label: 'Statement Date',
            tooltipInfo: getRelativeTime(statement.createdOn),
            description: getFormattedDate(statement.createdOn, AppConfig.dateMonthNameFormat)
        });

        if (statement.sentOn) {
            descriptions.push({
                label: 'Sent Date',
                tooltipInfo: getRelativeTime(statement.sentOn),
                description: getFormattedDate(statement.sentOn, AppConfig.dateMonthNameFormat)
            });
        }

        return descriptions;
    }, [statement?.statementId]);

    return <DescriptionsComponent descriptions={basicInfoDescriptions} />;
});
