import { makeAutoObservable } from 'mobx';
import { router } from '../../index';
import { NAVIGATION_KEY } from '../common/constants/navigationKey';
import { canViewMyEntities } from '../common/utils/user';
import { INVOICE_STATE, INVOICE_TYPE } from '../models/invoice';
import { STATEMENT_STATE } from '../models/statement';
import { UiPreferences } from '../models/uiPreferences';
import { LoggedInUser, PERMISSION, ROLE, STORAGE_KEY } from '../models/user';
import { StaticRoutes } from '../routing/RoutePaths';
import { store } from './store';

export default class UiStore {
    selectedNavigationKey: NAVIGATION_KEY = NAVIGATION_KEY.Dashboard;

    uiPreferences: UiPreferences = {
        consolidateInvoiceList: false,
        homePage: '/',
        showInvoiceOverview: false,
        showInvoiceDetailsLedesToggle: false,
        showMyEntitiesFilter: true
    };

    constructor() {
        makeAutoObservable(this);
    }

    setNavigationKey = (navigator: NAVIGATION_KEY) => {
        this.selectedNavigationKey = navigator;
    };

    setNavigationKeyByInvoiceState = (invoiceState?: INVOICE_STATE) => {
        switch (invoiceState) {
            case INVOICE_STATE.PENDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPending);
                break;
            case INVOICE_STATE.OUTSTANDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesOutstanding);
                break;
            case INVOICE_STATE.PAID:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPaid);
                break;
            case INVOICE_STATE.CONSOLIDATED:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesConsolidated);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Invoices);
        }
    };

    setNavigationKeyByStatementState = (statementState?: STATEMENT_STATE) => {
        switch (statementState) {
            case STATEMENT_STATE.SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsSent);
                break;
            case STATEMENT_STATE.NOT_SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsNotSent);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Statements);
        }
    };

    setUiPreferences = (user: LoggedInUser) => {
        if (!user) {
            return;
        }

        const isInvoiceManager = user.roles.some((role) =>
            [ROLE.BILLER, ROLE.BILLING_MANAGER, ROLE.COLLECTOR, ROLE.ADMIN].includes(role)
        );
        const isInvoiceManagerButNotAdmin = isInvoiceManager && !user.roles.includes(ROLE.ADMIN);
        const isTimekeeper = user.roles.includes(ROLE.TIME_KEEPER);
        const isTimekeeperWithClientMatterSecurityExemption =
            isTimekeeper &&
            user.permissions.includes(PERMISSION.EXEMPT_FROM_CLIENT_MATTER_SECURITY);
        const isEBillingServicesUser = user.roles.includes(ROLE.EBILLING_SERVICES);

        this.uiPreferences.consolidateInvoiceList = isTimekeeper;
        this.uiPreferences.homePage = isEBillingServicesUser
            ? StaticRoutes.INVOICE_LIST_PENDING
            : '/';
        this.uiPreferences.showMyEntitiesFilter =
            isInvoiceManagerButNotAdmin || isTimekeeperWithClientMatterSecurityExemption;
        this.uiPreferences.showInvoiceOverview =
            isInvoiceManager || isEBillingServicesUser || isTimekeeper;
        this.uiPreferences.showInvoiceDetailsLedesToggle = isInvoiceManager;

        if (store.userStore.iseBillingServicesUser) {
            store.invoiceStore.setInvoiceTypeFilter(INVOICE_TYPE.EBILLING);
        }

        // Set the myEntities default values
        store.invoiceStore.setMyInvoicesFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_INVOICES), user)
        );
        store.statementStore.setMyStatementFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_STATEMENTS), user)
        );
        store.clientStore.setMyClientsFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_CLIENTS), user)
        );
        store.matterStore.setMyMattersFilter(
            this.setDefaultMyEntities(localStorage.getItem(STORAGE_KEY.MY_MATTERS), user)
        );
    };

    private setDefaultMyEntities = (myEntities: string | null, user: LoggedInUser) => {
        return myEntities ? myEntities === 'true' : canViewMyEntities(user);
    };

    redirectUserPostLogin = () => {
        const redirectURL = store.userStore.getRedirectUrl();
        router.navigate(redirectURL ?? this.uiPreferences.homePage);
    };
}
