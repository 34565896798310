import { SoundTwoTone } from '@ant-design/icons';
import { Popover, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import RichTextViewer from '../../../app/common/components/wysiwyg/RichTextViewer';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    alertMessage?: string | null;
}

export default observer(function AlertMessageIndicator({ alertMessage }: Props) {
    if (!alertMessage) {
        return null;
    }

    const content = (
        <div style={{ maxWidth: '350px', width: '350px' }}>
            <RichTextViewer value={alertMessage} />
        </div>
    );

    const icon = <SoundTwoTone twoToneColor={ThemeColor.ColorError} />;

    return (
        <Popover
            title={
                <Space>
                    {icon}
                    {'Alert'}
                </Space>
            }
            content={content}
        >
            {icon}
        </Popover>
    );
});
