import { Checkbox, DatePicker, Flex, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import ActionForm from '../../../app/common/components/actionForm/ActionForm';
import { getFormattedDate } from '../../../app/common/utils/datetime';
import { Notify } from '../../../app/common/utils/notify';
import { CC_USER, Client } from '../../../app/models/client';
import { AppConfig } from '../../../app/models/config/appConfig';
import { BulkAction } from '../../../app/models/invoice';
import { Matter } from '../../../app/models/matter';
import { ClientOrMatterStatement } from '../../../app/models/statement';
import { NullableType } from '../../../app/models/utility';
import { useStore } from '../../../app/stores/store';
import EditEmailAddresses from '../../invoices/hooks/components/EditEmailAddresses';
import { getOneTimeExceptionEmailEditorTooltip } from '../../invoices/hooks/helpers/submit';
import DocumentViewer from '../../shared/documents/DocumentViewer';
import useCache from '../../shared/hooks/useCache';
import ModalTitle from '../../shared/ModalTitle';
import DeleteStatementModalBody from './components/DeleteStatementModalBody';
import SendStatementModalBody from './components/SendStatementModalBody';
import SendStatementsModalBody from './components/SendStatementsModalBody';
import ShareStatementsModalBody from './components/ShareStatementsModalBody';
import UploadClientStatementModalBody from './components/UploadClientStatementModalBody';
import UploadMatterStatementModalBody from './components/UploadMatterStatementModalBody';
import UploadStatementInList from './components/UploadStatementInListModalBody';

type ShareStatementData = NullableType<{
    ccUsers: CC_USER[];
}>;

const { Text } = Typography;

export default function useStatementActions<T extends ClientOrMatterStatement>() {
    const DefaultBulkAction: BulkAction = {
        canDisplay: false,
        canDo: true,
        warnings: []
    };
    const {
        modalStore: { openModal, closeModal },
        statementStore: { getStatementForMonth },
        statementStore: {
            clearError,
            loadStatements,
            shareStatement,
            markStatementAsSent,
            getStatementDocument
        }
    } = useStore();

    const [selectedStatements, setSelectedStatements] = useState<T[]>([]);
    const [selectedStatementIds, setSelectedStatementIds] = useState<React.Key[]>([]);
    const [canClearError, setCanClearError] = useState(DefaultBulkAction);
    const [canSendStatements, setCanSendStatements] = useState(DefaultBulkAction);
    const [canMarkAsSendStatements, setCanMarkAsSendStatements] = useState(DefaultBulkAction);
    const [canShareStatements, setCanShareStatements] = useState(DefaultBulkAction);
    const cachedStatement = useCache<T>();
    const cachedStatementDocuments = useCache<File>();

    const getStatementCount = (statements: T[], canAction: keyof T) => {
        let canActionCount = 0;
        statements.forEach((statement) => {
            if (statement[canAction]) {
                canActionCount++;
            }
        });
        return { canActionCount };
    };

    const updateCanClearErrorAction = (statements: T[]) => {
        const { canActionCount: canClearErrorCount } = getStatementCount(
            statements,
            'canClearError'
        );

        setCanClearError({
            canDisplay: !!canClearErrorCount,
            canDo: true,
            warnings: []
        });
    };

    const updateCanSendStatementsAction = (statements: T[]) => {
        const { canActionCount: canSendStatements } = getStatementCount(statements, 'canSend');

        setCanSendStatements({
            canDisplay: !!canSendStatements,
            canDo: true,
            warnings: []
        });
    };

    const updateCanMarkAsSendStatementsAction = (statements: T[]) => {
        const { canActionCount: canMarkAsSendStatements } = getStatementCount(
            statements,
            'canMarkAsSent'
        );

        setCanMarkAsSendStatements({
            canDisplay: !!canMarkAsSendStatements,
            canDo: true,
            warnings: []
        });
    };

    const updateCanShareStatementsAction = (statements: T[]) => {
        const { canActionCount: canShareStatements } = getStatementCount(statements, 'canShare');

        setCanShareStatements({
            canDisplay: !!canShareStatements,
            canDo: true,
            warnings: []
        });
    };

    const handleRowSelectionChange = (selectedRowKeys: React.Key[], selectedRows: T[]) => {
        setSelectedStatements(selectedRows);
        setSelectedStatementIds(selectedRowKeys);
        updateCanClearErrorAction(selectedRows);
        updateCanSendStatementsAction(selectedRows);
        updateCanMarkAsSendStatementsAction(selectedRows);
        updateCanShareStatementsAction(selectedRows);
    };

    const resetSelections = () => {
        setSelectedStatements([]);
        setSelectedStatementIds([]);
    };

    const handleSendClientStatement = async (
        client: Client,
        defaultMonth?: Date,
        needsAttention?: boolean,
        onSuccess?: () => Promise<any> | any
    ) => {
        const getCcToClient = () => {
            const ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[] = [];
            if (client.billingTimekeeperId) {
                ccTo.push(CC_USER.BILLING_TIMEKEEPER);
            }
            if (
                client.collectionTimekeeperId &&
                client.billingTimekeeperId !== client.collectionTimekeeperId
            ) {
                ccTo.push(CC_USER.COLLECTION_TIMEKEEPER);
            }
            return ccTo;
        };

        openModal({
            title: 'Send Statement',
            body: (
                <SendStatementModalBody
                    clientId={client.clientId}
                    doNotContact={client.doNotContact}
                    defaultMonth={defaultMonth}
                    needsAttention={needsAttention}
                    recipients={client.computedStatementRecipients}
                    billingTimekeeperName={client.billingTimekeeperName ?? undefined}
                    collectionTimekeeperName={client.collectionTimekeeperName ?? undefined}
                    ccTo={getCcToClient()}
                    onSuccess={onSuccess}
                    currency={client.currency}
                />
            ),
            width: 650,
            footer: false
        });
    };

    const handleSendMatterStatement = async (
        matter: Matter,
        defaultMonth?: Date,
        needsAttention?: boolean,
        onSuccess?: () => Promise<any> | any
    ) => {
        const getCcToMatter = () => {
            const ccTo: Exclude<CC_USER, CC_USER.CURRENT_USER>[] = [];
            if (matter.billingTimekeeperId) {
                ccTo.push(CC_USER.BILLING_TIMEKEEPER);
            }
            if (
                matter.collectionTimekeeperId &&
                matter.billingTimekeeperId !== matter.collectionTimekeeperId
            ) {
                ccTo.push(CC_USER.COLLECTION_TIMEKEEPER);
            }
            return ccTo;
        };

        openModal({
            title: 'Send Matter Statement',
            body: (
                <SendStatementModalBody
                    clientId={matter.client.clientId}
                    matterId={matter.matterId}
                    doNotContact={matter.doNotContact}
                    defaultMonth={defaultMonth}
                    needsAttention={needsAttention}
                    recipients={matter?.computedStatementRecipients}
                    billingTimekeeperName={matter.billingTimekeeperName ?? undefined}
                    collectionTimekeeperName={matter.collectionTimekeeperName ?? undefined}
                    ccTo={getCcToMatter()}
                    // When a statement is sent, we would have stale statements,
                    // Hence we can just clear it.
                    onSuccess={onSuccess}
                    currency={matter.currency}
                />
            ),
            width: 650,
            footer: false
        });
    };
    const handleUploadClientStatement = async (
        client: Client,
        onSuccess?: () => Promise<any> | any
    ) => {
        openModal({
            title: 'Upload Statement',
            body: (
                <UploadClientStatementModalBody
                    clientId={client.clientId}
                    currency={client.currency}
                    onSuccess={onSuccess}
                />
            ),
            width: 650,
            footer: false
        });
    };

    const handleUploadMatterStatement = async (
        matter: Matter,
        onSuccess?: () => Promise<any> | any
    ) => {
        openModal({
            title: 'Upload Statement',
            body: (
                <UploadMatterStatementModalBody
                    clientId={matter.client.clientId}
                    matterId={matter.matterId}
                    currency={matter.currency}
                    onSuccess={onSuccess}
                />
            ),
            width: 650,
            footer: false
        });
    };

    const handleDeleteStatement = async (
        statement: T,
        navigateToParent: boolean,
        onSuccess?: () => Promise<any> | any
    ) => {
        openModal({
            title: `Delete Statement`,
            body: (
                <DeleteStatementModalBody
                    statement={statement}
                    navigateToParent={navigateToParent}
                    onSuccess={onSuccess}
                />
            ),
            width: 650,
            footer: false,
            easyClose: true
        });
    };

    const handleSendStatements = (
        statements: T[],
        isMultipleClientsSelected: boolean,
        markAsSent: boolean,
        onFinish: () => void
    ) => {
        const sendableStatementIds = statements
            .filter((s) => (markAsSent ? s.canMarkAsSent : s.canSend))
            .map((s) => s.statementId);

        if (!sendableStatementIds.length) {
            return;
        }

        const unSendableStatementsCount = statements.length - sendableStatementIds.length;
        openModal({
            title: (
                <ModalTitle
                    title={markAsSent ? 'Mark Sent' : 'Send Statements'}
                    warn={!!unSendableStatementsCount}
                />
            ),
            body: (
                <SendStatementsModalBody
                    statementIds={sendableStatementIds}
                    unSendableStatementsCount={unSendableStatementsCount}
                    isMultipleClientSelected={isMultipleClientsSelected}
                    onFinish={onFinish}
                    markAsSent={markAsSent}
                />
            ),
            footer: false
        });
    };

    const handleShareStatement = (statement: T, onSuccess?: () => Promise<any> | any) => {
        const ModalBody = () => {
            const [emails, setEmails] = useState<string[] | null>(null);

            const handleOnOK = async (data: ShareStatementData) => {
                const { ccUsers } = data;
                if (await shareStatement(statement.statementId, ccUsers, emails)) {
                    Notify.success(
                        `Statement ${statement.statementId} successfully shared with the client.`,
                        'Success'
                    );
                    onSuccess?.();
                }

                closeModal();
            };

            return (
                <ActionForm<ShareStatementData> okText='Share' onOk={handleOnOK}>
                    <ActionForm.Item>
                        <Text>Are you sure you want to share this statement with the client?</Text>
                    </ActionForm.Item>
                    <ActionForm.Item>
                        <EditEmailAddresses
                            onChange={(emails) => {
                                setEmails(emails);
                            }}
                            emails={statement.recipients ?? ''}
                            tooltipInfo={getOneTimeExceptionEmailEditorTooltip()}
                            defaultEditMode={!!statement.error || !statement.recipients?.length}
                        />
                    </ActionForm.Item>
                    <ActionForm.Item name='ccUsers'>
                        <ActionForm.CcUsers
                            bulkAction={false}
                            ccTo={[CC_USER.BILLING_TIMEKEEPER]}
                            billingTimekeeperName={statement.billingTimekeeperName}
                        />
                    </ActionForm.Item>
                </ActionForm>
            );
        };

        openModal({
            title: `Share Statement`,
            body: <ModalBody />,
            footer: false
        });
    };

    const handleBulkClearError = async (statements: T[], onFinish?: () => Promise<any> | any) => {
        let refreshPage = false;
        const clearableStatements = statements.filter((s) => s.canClearError);
        const clearAndMarkAsSendableStatements = statements.filter(
            (s) => s.canClearError && s.canMarkAsSent
        );

        const ModalBody = () => {
            const [markAsSent, setMarkAsSent] = useState(true);
            const [sentOn, setSentOn] = useState(dayjs());

            const handleOnOk = async () => {
                // If there are no actionable statements just abort.
                // This behavior should be improved as we futher use this 'silently ignoring
                // unactionable item' behavior across the application
                if (
                    (markAsSent && !clearAndMarkAsSendableStatements.length) ||
                    (!markAsSent && !clearableStatements.length)
                ) {
                    return;
                }
                const errorCleared = await clearError(
                    markAsSent
                        ? clearAndMarkAsSendableStatements.map((i) => i.statementId)
                        : clearableStatements.map((i) => i.statementId),
                    markAsSent,
                    sentOn && markAsSent ? sentOn.format(AppConfig.isoDateFormat) : null
                );

                if (errorCleared && markAsSent) {
                    refreshPage = true;
                    Notify.success(
                        `Successfully cleared errors for ${clearAndMarkAsSendableStatements.length} statement(s) and marked statement(s) as sent.`
                    );
                } else if (errorCleared) {
                    refreshPage = true;
                    Notify.success(
                        `Successfully cleared errors for ${clearableStatements.length} statement(s)`
                    );
                } else {
                    Notify.error(`An error occurred while clearing the error. Please try again.`);
                }
                onFinish?.();
                closeModal();
                if (refreshPage) {
                    // Used only in errors page
                    loadStatements();
                }
            };

            return (
                <ActionForm onOk={handleOnOk} okText='Clear'>
                    <Text>
                        Are you sure you want to clear the errors for the selected statements?
                    </Text>

                    <ActionForm.Item>
                        <Flex vertical gap={10}>
                            <Text>
                                If these statements are already sent to the Client, you can also
                                mark them as sent by checking the option below.
                            </Text>
                            <Checkbox
                                checked={markAsSent}
                                onChange={(e) => {
                                    setMarkAsSent(e.target.checked);
                                    setSentOn(dayjs());
                                }}
                            >
                                Mark Sent
                            </Checkbox>
                            {markAsSent && (
                                <>
                                    <Text>Select the date these statements were sent:</Text>
                                    <Space>
                                        <DatePicker
                                            defaultValue={sentOn}
                                            onChange={(_, dateString) => {
                                                if (typeof dateString === 'string') {
                                                    setSentOn(dayjs(dateString));
                                                }
                                            }}
                                            style={{ width: 160 }}
                                            allowClear={false}
                                            format={AppConfig.dateMonthNameFormat}
                                            disabledDate={(current: Dayjs) => {
                                                return current && current.isAfter(dayjs(), 'day');
                                            }}
                                        />
                                    </Space>
                                </>
                            )}
                        </Flex>
                    </ActionForm.Item>
                </ActionForm>
            );
        };

        openModal({
            title: 'Clear Errors',
            body: <ModalBody />,
            okText: 'Save',
            footer: false,
            onCancel: closeModal
        });
    };

    const handleClearError = async (statement: T, onSuccess?: () => Promise<any> | any) => {
        const ModalBody = () => {
            const [markAsSent, setMarkAsSent] = useState(!!statement.canMarkAsSent);
            const [sentOn, setSentOn] = useState(dayjs());

            let refreshPage = false;
            const handleOnOK = async () => {
                const errorCleared = await clearError(
                    [statement.statementId],
                    markAsSent,
                    sentOn && markAsSent ? sentOn.format(AppConfig.isoDateFormat) : null
                );
                if (errorCleared && markAsSent) {
                    Notify.success(
                        `Successfully cleared error for ${statement.statementId} and marked it as sent.`
                    );
                    refreshPage = true;
                } else if (errorCleared) {
                    Notify.success(`Successfully cleared error for ${statement.statementId}.`);
                    refreshPage = true;
                } else {
                    Notify.error(`An error occurred while clearing the error. Please try again.`);
                }

                if (refreshPage) {
                    await onSuccess?.();
                }
                closeModal();
            };

            return (
                <ActionForm okText='Clear' onOk={handleOnOK}>
                    <Text>Are you sure you want to clear the error for this statement?</Text>
                    {statement.canMarkAsSent && (
                        <>
                            <Text>
                                If this statement is already sent to the Client, you can also mark
                                it as sent by checking the option below.
                            </Text>
                            <ActionForm.Item>
                                <Flex vertical gap={10}>
                                    <Checkbox
                                        checked={markAsSent}
                                        onChange={(e) => {
                                            setMarkAsSent(e.target.checked);
                                            setSentOn(dayjs());
                                        }}
                                    >
                                        Mark Sent
                                    </Checkbox>
                                    {markAsSent && (
                                        <>
                                            <Text>Select the date this statement was sent:</Text>
                                            <Space>
                                                <DatePicker
                                                    defaultValue={sentOn}
                                                    onChange={(_, dateString) => {
                                                        if (typeof dateString === 'string') {
                                                            setSentOn(dayjs(dateString));
                                                        }
                                                    }}
                                                    style={{ width: 160 }}
                                                    allowClear={false}
                                                    disabledDate={(current) => {
                                                        return (
                                                            current.isBefore(
                                                                dayjs(
                                                                    statement.statementMonth
                                                                ).startOf('day'),
                                                                'day'
                                                            ) ||
                                                            current.isAfter(
                                                                dayjs().endOf('day'),
                                                                'day'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Space>
                                        </>
                                    )}
                                </Flex>
                            </ActionForm.Item>
                        </>
                    )}
                </ActionForm>
            );
        };

        openModal({
            title: 'Clear Error',
            body: <ModalBody />,
            footer: false,
            onCancel: closeModal
        });
    };

    const handleUploadStatement = async () => {
        openModal({
            title: 'Upload Statement',
            body: <UploadStatementInList />,
            width: 650,
            footer: false
        });
    };

    const handleMarkStatementAsSent = (statement: T, onSuccess?: () => Promise<any> | any) => {
        const ModalBody = () => {
            const [sentOn, setSentOn] = useState<Dayjs | null>(dayjs());

            const handleOnOK = async () => {
                if (
                    await markStatementAsSent(
                        statement.statementId,
                        dayjs(sentOn).format(AppConfig.isoDateFormat)
                    )
                ) {
                    Notify.success(
                        `Statement ${statement.statementId} successfully marked as sent.`,
                        'Success'
                    );
                    onSuccess?.();
                }

                closeModal();
            };

            return (
                <ActionForm okText='Mark Sent' onOk={handleOnOK}>
                    <Flex vertical gap={10}>
                        <Text>Select the date this statement was sent:</Text>
                        <Space>
                            <DatePicker
                                onChange={(_, dateString) => {
                                    if (typeof dateString === 'string') {
                                        setSentOn(dayjs(dateString));
                                    }
                                }}
                                style={{ width: 160 }}
                                defaultValue={sentOn}
                                format={AppConfig.dateMonthNameFormat}
                                disabledDate={(current) => {
                                    return (
                                        current.isBefore(
                                            dayjs(statement.createdOn).startOf('day'),
                                            'day'
                                        ) || current.isAfter(dayjs().endOf('day'), 'day')
                                    );
                                }}
                                allowClear={false}
                            />
                        </Space>
                    </Flex>
                </ActionForm>
            );
        };

        openModal({
            title: `Mark Sent`,
            body: <ModalBody />,
            footer: false
        });
    };

    const handleLoadStatementDocument = async (statement: T) => {
        const cachedDocument = cachedStatementDocuments.get(statement.statementId);
        if (cachedDocument) {
            return cachedDocument;
        }

        const statementDocument = await getStatementDocument(statement.statementId);

        if (statementDocument) {
            const file = new File([statementDocument], statement.statementDocumentName, {
                type: 'application/pdf'
            });
            cachedStatementDocuments.add(statement.statementId, file);
            return file;
        }
        return null;
    };

    const handleFetchStatement = async (
        selectedClientId: string,
        date: Dayjs,
        matterId?: string
    ) => {
        const statementCacheKey = `${selectedClientId}-${matterId || 'noMatter'}-${date.format('YYYY-MM-DD')}`;

        const cachedStatement_ = cachedStatement.get(statementCacheKey);
        if (cachedStatement_) {
            return cachedStatement_;
        }
        const statement = await getStatementForMonth(
            selectedClientId,
            date.format('YYYY-MM-DD'),
            matterId
        );

        if (statement) {
            cachedStatement.add(statementCacheKey, statement as unknown as T);
            return statement;
        }

        return null;
    };

    const handleViewStatementPDFDocument = async (statement: T) => {
        const statementDocument = await getStatementDocument(statement.statementId);

        if (statementDocument) {
            openModal({
                title: `Statement - ${getFormattedDate(statement.statementMonth, AppConfig.monthYearFormat)}`,
                body: (
                    <DocumentViewer
                        document={statementDocument}
                        documentName={statement.statementDocumentName}
                        loading={false}
                    />
                ),
                width: '60%',
                footer: false,
                easyClose: true
            });
        }
    };

    const handleShareStatements = (
        statements: T[],
        isMultipleClientsSelected: boolean,
        onFinish: () => void
    ) => {
        const shareableStatementIds = statements
            .filter((s) => s.canShare)
            .map((s) => s.statementId);

        if (!shareableStatementIds.length) {
            return;
        }
        const unShareableStatementsCount = statements.length - shareableStatementIds.length;

        openModal({
            title: <ModalTitle title='Share Statements' warn={!!unShareableStatementsCount} />,
            body: (
                <ShareStatementsModalBody
                    statementIds={shareableStatementIds}
                    onFinish={onFinish}
                    unShareableStatementsCount={unShareableStatementsCount}
                    isMultipleClientsSelected={isMultipleClientsSelected}
                />
            ),
            footer: false
        });
    };

    return {
        handleSendStatements,
        handleSendClientStatement,
        handleShareStatement,
        handleClearError,
        handleBulkClearError,
        handleRowSelectionChange,
        selectedStatementIds,
        setSelectedStatementIds,
        selectedStatements,
        setSelectedStatements,
        setCanClearError,
        canClearError,
        canSendStatements,
        canMarkAsSendStatements,
        canShareStatements,
        resetSelections,
        handleSendMatterStatement,
        handleUploadClientStatement,
        handleUploadMatterStatement,
        handleUploadStatement,
        handleLoadStatementDocument,
        handleFetchStatement,
        handleViewStatementPDFDocument,
        handleDeleteStatement,
        handleMarkStatementAsSent,
        handleShareStatements
    };
}
