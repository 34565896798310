export const enum NAVIGATION_KEY {
    Dashboard = 'dashboard',
    Invoices = 'invoices!',
    InvoicesPending = 'invoices!pending',
    InvoicesOutstanding = 'invoices!outstanding',
    InvoicesPaid = 'invoices!paid',
    InvoicesConsolidated = 'invoices!consolidated',
    NeedsAttention = 'needs-attention',
    Reminders = 'reminders',
    Statements = 'statements',
    StatementsSent = 'statements!sent',
    StatementsNotSent = 'statements!notSent',
    Settings = 'settings',
    SettingsClientPortal = 'settings!client-portal',
    SettingsUsers = 'settings!users',
    Clients = 'clients',
    Payments = 'payments',
    Matters = 'matters'
}
