import { Button, Checkbox, Col, Flex, Form, Row, SelectProps, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import Loader from '../../../../app/common/components/Loader';
import { Notify } from '../../../../app/common/utils/notify';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { Client } from '../../../../app/models/client';
import { Currency } from '../../../../app/models/invoice';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import { useStore } from '../../../../app/stores/store';
import ClientLookup from '../../../shared/lookup/ClientLookup';
import MatterLookup from '../../../shared/lookup/MatterLookup';
import useStatementActions from '../useStatementActions';
import StatementFetchModalBody, { OnChangeData } from './StatementFetchModalBody';

export default observer(function UploadStatementInList() {
    const [matterOptions, setMatterOptions] = useState<SelectProps<object>['options']>([]);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [currency, setCurrency] = useState<Currency | undefined>(undefined);
    const foreignCurrency = isForeignCurrency(currency);
    const [statementMonth, setStatementMonth] = useState<Dayjs | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const [amount, setAmount] = useState<number | null>(0);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(0);
    const [matterId, setMatterId] = useState<string | undefined>(undefined);
    const [overrideUpload, setOverrideUpload] = useState(false);
    const [existingStatement, setExistingStatement] = useState<ClientOrMatterStatement | null>(
        null
    );

    const {
        matterStore: { lookupMattersByClient },
        statementStore: {
            createStatement,
            loadStatements,
            creatingStatement,
            loadingStatements,
            loadingStatementForMonth
        },
        modalStore: { closeModal },
        clientStore: { getClient }
    } = useStore();

    const { handleLoadStatementDocument, handleFetchStatement } = useStatementActions();

    const handleMonthChange = useCallback(
        async (dateString: Dayjs | null) => {
            let result: OnChangeData = {
                month: null,
                amount: null,
                document: null,
                foreignCurrencyAmount: null,
                currency: null
            };
            if (!selectedClient || !dateString) {
                return result;
            }

            const statement = await handleFetchStatement(
                selectedClient.clientId,
                dateString,
                matterId
            );

            setExistingStatement(statement);
            setCurrency(statement?.currency);

            if (statement) {
                const document = await handleLoadStatementDocument(statement);

                if (document) {
                    setStatementDocument(document);

                    result = {
                        month: dateString,
                        amount: statement.amount,
                        document: document,
                        foreignCurrencyAmount: statement.foreignCurrency?.amount ?? null,
                        currency: statement.currency
                    };
                }
            }
            return result;
        },
        [selectedClient, matterId]
    );

    const handleOnChange = async (data: OnChangeData) => {
        setStatementDocument(data.document);
        setStatementMonth(data?.month);
        setAmount(data.amount);
        setForeignCurrencyAmount(data.foreignCurrencyAmount);
    };

    const handleClientSelect = async (value: string) => {
        const client = await getClient(value);
        client && setSelectedClient(client);
        const matters = await lookupMattersByClient(value, '');
        if (matters) {
            const newMatterOptions = matters.map((matter) => ({
                label: `${matter.matterId} - ${matter.name}`,
                value: matter.matterId
            }));
            setMatterOptions(newMatterOptions);
        } else {
            setMatterOptions([]);
        }
    };

    const handleClearMatter = () => {
        setMatterId(undefined);
        setStatementDocument(null);
        setAmount(null);
        setForeignCurrencyAmount(null);
        setExistingStatement(null);
        setMatterOptions([]);
    };

    const handleOnOK = async () => {
        if (
            statementDocument &&
            selectedClient &&
            amount !== null &&
            statementMonth &&
            (foreignCurrency ? foreignCurrencyAmount !== null : true)
        ) {
            const success = await createStatement(selectedClient.clientId, {
                statementId: null,
                statementDocument: statementDocument,
                statementMonth: dayjs(statementMonth).format('YYYY-MM'),
                amount: amount !== null ? amount : (existingStatement?.amount ?? null),
                matterId: matterId ?? null,
                foreignCurrencyAmount: foreignCurrency ? foreignCurrencyAmount : null
            });

            if (success) {
                Notify.success('Statement uploaded successfully.');
                closeModal();
            }
            loadStatements();
        }
    };

    const disableSendButton = !(
        statementDocument &&
        amount !== null &&
        statementMonth &&
        (!foreignCurrency || foreignCurrencyAmount !== null) &&
        (!existingStatement?.canShare || overrideUpload)
    );

    return (
        <Loader spinning={creatingStatement || loadingStatements || loadingStatementForMonth}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item
                            required
                            label={<FormLabel label='Client' />}
                            colon={false}
                            layout='vertical'
                        >
                            <ClientLookup
                                onSelect={handleClientSelect}
                                onClear={() => {
                                    setSelectedClient(null);
                                    setMatterId('');
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<FormLabel label='Matter' />}
                            colon={false}
                            layout='vertical'
                        >
                            <MatterLookup
                                clientId={selectedClient?.clientId ?? ''}
                                onSelect={(value) => setMatterId(value)}
                                options={matterOptions}
                                disabled={selectedClient === null}
                                onClear={() => handleClearMatter()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <StatementFetchModalBody
                    clientId={selectedClient?.clientId ?? ''}
                    currency={selectedClient?.currency}
                    matterId={matterId}
                    onChange={handleOnChange}
                    onMonthChange={handleMonthChange}
                    disabled={selectedClient === null}
                />
                {existingStatement?.canShare && (
                    <Form.Item>
                        <Checkbox onChange={(e) => setOverrideUpload(e.target.checked)}>
                            This Statement was previously sent to the Client. Please confirm that
                            you want to re-upload it.
                        </Checkbox>
                    </Form.Item>
                )}
                <Typography.Text type='secondary'>
                    Saving this will overwrite any existing Statement for this month for the
                    selected Client (and Matter, if specified) and mark it as not sent.
                </Typography.Text>
                <Flex justify='flex-end'>
                    <Space>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button onClick={handleOnOK} type='primary' disabled={disableSendButton}>
                            Save
                        </Button>
                    </Space>
                </Flex>
            </Space>
        </Loader>
    );
});
