import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../app/layout/PageHeader';
import { Alert, Button, Col, Row, Space } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ThemeColor } from '../../../app/common/constants/color';
import NavigationBreadcrumb from '../../../app/common/components/Breadcrumb/NavigationBreadcrumb';
import { ENTITY_TYPE } from '../../../app/models/entity';
import Loader from '../../../app/common/components/Loader';
import TooltipIcon from '../../../app/common/components/TooltipIcon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import StatementDetailsCard from './StatementDetailsCard';
import useStatementActions from '../hooks/useStatementActions';
import DocumentDetailsActionsArea from './DocumentActionArea';
import { NAVIGATION_KEY } from '../../../app/common/constants/navigationKey';
import Menus from '../Menus/Menus';
import { STATEMENT_STATUS } from '../../../app/models/statement';
import DetailsPageLoading from '../../../app/layout/DetailsPageLoading';
import { AppConfig } from '../../../app/models/config/appConfig';
import { getFormattedDate } from '../../../app/common/utils/datetime';

export default observer(function StatementDetails() {
    const {
        statementStore: { loadStatement, selectedStatement: statement, loadingStatement },
        uiStore: { setNavigationKey }
    } = useStore();
    const { handleClearError } = useStatementActions();

    const { statementId } = useParams<{ statementId: string }>();

    useEffect(() => {
        const loadStatementData = async () => {
            if (statementId) {
                const statement = await loadStatement(statementId, true, ['Activities']);

                if (statement) {
                    if (statement.statement?.error) {
                        setNavigationKey(NAVIGATION_KEY.NeedsAttention);
                    } else {
                        setNavigationKey(
                            statement.statement?.status === STATEMENT_STATUS.ReadyToSend
                                ? NAVIGATION_KEY.StatementsNotSent
                                : NAVIGATION_KEY.StatementsSent
                        );
                    }
                }
            }
        };

        loadStatementData();
    }, []);

    return (
        <>
            {loadingStatement ? (
                <DetailsPageLoading />
            ) : (
                <Loader spinning={loadingStatement}>
                    <Row gutter={[24, 5]}>
                        <Col span={18}>
                            <Space direction='vertical'>
                                {statement && (
                                    <NavigationBreadcrumb
                                        entityType={ENTITY_TYPE.STATEMENT}
                                        statement={statement}
                                        showBackNavigation
                                    />
                                )}
                                <PageHeader
                                    title={`Statement - ${getFormattedDate(statement?.statementMonth, AppConfig.monthYearFormat)}`}
                                    doNotTruncateTitle
                                />
                            </Space>
                            {statement?.error && (
                                <Alert
                                    description={
                                        <Paragraph
                                            ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                                            className='error-paragraph'
                                        >
                                            {statement.error}
                                        </Paragraph>
                                    }
                                    type='error'
                                    closable={false}
                                    className='error-alert'
                                    banner
                                    showIcon={true}
                                    message={
                                        statement.canSend ? (
                                            <Space>
                                                {'Submission Error'}
                                                <TooltipIcon title='For statements that need attention, you have the option to send them to a different email address during Submission.' />
                                            </Space>
                                        ) : (
                                            'Submission Error'
                                        )
                                    }
                                    icon={
                                        <ExclamationCircleFilled
                                            style={{ color: ThemeColor.ColorError }}
                                        />
                                    }
                                    action={
                                        statement.canClearError && (
                                            <Button
                                                size='small'
                                                type='primary'
                                                danger
                                                onClick={() =>
                                                    handleClearError(statement, () =>
                                                        loadStatement(statement.statementId, true, [
                                                            'Activities'
                                                        ])
                                                    )
                                                }
                                            >
                                                Clear
                                            </Button>
                                        )
                                    }
                                />
                            )}
                            <Row gutter={[0, 16]}>
                                <Col span={24}>
                                    <StatementDetailsCard />
                                </Col>
                                <Col span={24}>
                                    <Menus />
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            span={6}
                            className='document-details-action-area document-details-action-area-border'
                        >
                            <DocumentDetailsActionsArea />
                        </Col>
                    </Row>
                </Loader>
            )}
        </>
    );
});
